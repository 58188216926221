import { MOODLE_RESET_PIN_PATH_NAME, MOODLE_RESET_PIN_FLOW, MOODLE_RESET_PIN_MAPPING } from '../../constants/config';
import { HTTP_REQUEST_STATUS } from '../../constants/constants';
import {
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_IN_PROGRESS,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_SUCCESS,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_ERROR,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_NONE,

    MOODLE_RESET_PIN_ANSWER_VERIFIED,

    MOODLE_RESET_PIN_IN_PROGRESS,
    MOODLE_RESET_PIN_SUCCESS,
    MOODLE_RESET_PIN_ERROR,
    MOODLE_RESET_PIN_NONE,

    MOODLE_RESET_PIN_HIDE_SUCCESS_DIALOG,

    MOODLE_RESET_PIN_NAVIGATION
} from '../../constants/actions/moodleRegistration/actionTypesMoodleResetPin';
import AppUtils from '../../util/util';

const intialState = {
    currenResetPinPage: MOODLE_RESET_PIN_FLOW.ACCOUNT_DETAILS_PAGE,
    supportingLanguages: [],
    userResetPinDetails: {},
    validateUserDetailsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    isUserDetailsValid: false,
    isValidAnswer: true,
    resetPINStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    isPINUpdated: false,
    showPINResetSuccessDalog: false
};

const ReducerMoodleResetPin = (state = intialState, action) => {

    switch (action.type) {

        case MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_IN_PROGRESS: {
            return {
                ...state,
                validateUserDetailsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_SUCCESS: {

            let userResetPinDetails = {};
            let currenResetPinPage = '';
            let isUserDetailsValid = false;

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {

                if (action.payload.data['responseStatus'] === 'InvalidDetails') {
                    currenResetPinPage = MOODLE_RESET_PIN_FLOW.ACCOUNT_DETAILS_PAGE;
                }
                else {
                    userResetPinDetails = action.payload.data;
                    currenResetPinPage = MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_QUESTION_PAGE;
                    isUserDetailsValid = true;

                    /**Adding navigation to browser history to handle back press */
                    AppUtils.pushNavigationHistory(MOODLE_RESET_PIN_PATH_NAME.SECURITY_QUESTION_PAGE);
                }
            }
            return {
                ...state,
                validateUserDetailsStatus: HTTP_REQUEST_STATUS.SUCCESS,
                userResetPinDetails: userResetPinDetails,
                currenResetPinPage: currenResetPinPage,
                isUserDetailsValid: isUserDetailsValid
            }
        }

        case MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_ERROR: {
            return {
                ...state,
                validateUserDetailsStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_NONE: {
            return {
                ...state,
                validateUserDetailsStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_RESET_PIN_ANSWER_VERIFIED: {

            /**Adding navigation to browser history to handle back press */
            AppUtils.pushNavigationHistory(MOODLE_RESET_PIN_PATH_NAME.SECURITY_PIN_PAGE);

            return {
                ...state,
                currenResetPinPage: MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_PIN_PAGE
            }
        }

        case MOODLE_RESET_PIN_IN_PROGRESS: {
            return {
                ...state,
                resetPINStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_RESET_PIN_SUCCESS: {

            let isPINUpdated = false;
            let showPINResetSuccessDalog = false;

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {
                if (action.payload.data['responseStatus'] === 'PINUpdatedSuccessfully...') {
                    isPINUpdated = true;
                    showPINResetSuccessDalog = true;

                    /**Adding navigation to browser history to handle back press */
                    AppUtils.pushNavigationHistory(MOODLE_RESET_PIN_PATH_NAME.OPENING_PAGE);
                }
                else if (action.payload.data['responseStatus'] === 'InvalidDetails') {
                    isPINUpdated = false;
                }
            }
            return {
                ...state,
                resetPINStatus: HTTP_REQUEST_STATUS.SUCCESS,
                isPINUpdated: isPINUpdated,
                showPINResetSuccessDalog: showPINResetSuccessDalog
            }
        }

        case MOODLE_RESET_PIN_ERROR: {
            return {
                ...state,
                resetPINStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_RESET_PIN_NONE: {
            return {
                ...state,
                resetPINStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_RESET_PIN_HIDE_SUCCESS_DIALOG: {
            return {
                ...state,
                showPINResetSuccessDalog: false
            }
        }

        case MOODLE_RESET_PIN_NAVIGATION: {
            return {
                ...state,
                currenResetPinPage: MOODLE_RESET_PIN_MAPPING[action.payload]
            }
        }

        default:
            return state;
    }

};

export default ReducerMoodleResetPin;