import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Select,
    Avatar,
    IconButton,
    MobileStepper
} from '@material-ui/core';
import CenterImageDisplay from '../components/moodleRegistration/CenterImageComponent';
import HeaderLogoComponent from '../components/moodleRegistration/HeaderAndSupportLanguageComponent';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { connect } from 'react-redux';
import {
    companyCodeInputForm,
    verifyCompanyCode,
    toggleDialog,
    companyVerified,
    verifyCompanyCodeInProgress,
    verifyEmployee,
    verifyEmployeeInProgress,
    verifyEmployeeNone,
    verifyCompanyCodeNone,
    confirmDetails,
    savePIN,
    getSecurityQuestions,
    getSecurityQuestionsNone,
    registerUser,
    registerUserInProgress,
    registerUserNone,
    hideSuccessDialog,
    hideWelcomeDialog,
    editCompanyCode,
    editEmployeeId,
    getAvtarListInProgress,
    getAvtarList,
    getAvtarListNone,
    setCurrentPage
} from '../actions/moodleRegistration/actionMoodleRegistration';
import {
    getSupportingLanguagesInProgress,
    getSupportingLanguages,
    getSupportingLanguagesNone,
    getTranslations,
    getTranslationsNone,
    changeCurrentLanguage
} from '../actions/moodleRegistration/actionMoodleResources';
import { HTTP_REQUEST_STATUS } from '../constants/constants';
import { PRIMARY_COLOR_BLUE, SUB_NOTE_GREAY } from '../constants/customUIConfig';
import AppUtils from '../util/util';
import { MOODLE_REGISTRATION_FLOW, MOODLE_REGISTRATION_PATH_NAME, PIN_SIZE } from '../constants/config';
import HeaderNote from '../components/moodleRegistration/HeaderNote';
import FooterNote from '../components/moodleRegistration/FooterNote';
import ConfirmationDialog from '../components/moodleRegistration/ConfirmationDialog';
import SecurityPINPage from '../components/moodleRegistration/SecurityPINPage';
import { privacyPolicyUrl } from '../util/keys';

class MoodleRegistration extends React.Component {

    constructor() {
        super();

        this.state = {
            companyId: '',
            employeeId: '',
            pin: new Array(PIN_SIZE).fill(""),
            selectedQuestionId: 1,
            answer: '',
            avtarId: 1,
            showAvtarDialog: false,
            maxSteps: 4,
            isTermsAgreed: true,
            currentPageIdentifier: 0
        };
    }

    componentDidMount() {
        try {

            /**Api call to get translations */
            this.props.getTranslations();

            /**Api call to get supporting languages */
            this.props.getSupportingLanguagesInProgress();
            this.props.getSupportingLanguages();

            /**Set current page to company if user comes from login page */
            let pathName = window.location.pathname;

            if (typeof pathName !== undefined && pathName === MOODLE_REGISTRATION_PATH_NAME.COMPANY_PAGE) {
                this.props.setCurrentPage(1);
            }

            /**Adding Event listener for back button press */
            window.addEventListener('popstate', this.handleBackButton);

        } catch (error) {
            console.error('componentDidMount : error :', error);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            currentPage,
            verifyCompanyStatus,
            verifyEmployeeStatus,
            isValidUser,
            securityQuestionsFetchStatus,
            fetchSecurityQuestions,
            registerUserStatus,
            showSuccessDialog,
            showWelcomeDialog,
            supportingLanguagesFetchStatus,
            translaionsFetchStatus,
            moodleTranslationsByLanguage,
            atarFetchStatus,
            isCompanyValid
        } = this.props;

        const { currentPageIdentifier } = this.state;

        try {
            let alertMessage = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_fail'];

            if (currentPage === MOODLE_REGISTRATION_FLOW.LOGIN_PAGE) {
                AppUtils.loadMoodleLoginPage();
            }

            if (verifyCompanyStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.verifyCompanyCodeNone();
            }

            if (verifyCompanyStatus === HTTP_REQUEST_STATUS.SUCCESS && !isCompanyValid) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_invalid_company']);
                this.props.verifyCompanyCodeNone();
            }

            if (atarFetchStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.getAvtarListNone();
            }

            if (verifyEmployeeStatus === HTTP_REQUEST_STATUS.SUCCESS && isValidUser) {

                /**Increase counter by 1 when user moves to next page */
                this.handleNavigationCounter();

                this.props.getAvtarListInProgress();
                this.props.getAvtarList();
                this.props.verifyEmployeeNone();
            }

            if (verifyEmployeeStatus === HTTP_REQUEST_STATUS.SUCCESS && prevProps.isValidUser !== false && isValidUser === false) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_invalid_user']);
                this.props.verifyEmployeeNone();
            }

            if (verifyEmployeeStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.verifyEmployeeNone();
            }

            if (fetchSecurityQuestions) {
                this.props.getSecurityQuestions();
                this.props.getSecurityQuestionsNone();
            }

            if (securityQuestionsFetchStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.getSecurityQuestionsNone();
            }

            if (registerUserStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.registerUserNone();
            }

            if (showSuccessDialog) {
                setTimeout(() => {
                    this.props.hideSuccessDialog();
                    AppUtils.loadMoodleLoginPage();
                }, 2000);
            }

            if (showWelcomeDialog) {
                setTimeout(() => {
                    this.props.hideWelcomeDialog();
                    AppUtils.loadMoodleLoginPage();
                }, 2000);
            }

            if (supportingLanguagesFetchStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.getSupportingLanguagesNone();
            }

            if (translaionsFetchStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.getTranslationsNone();
            }

            if (prevState.currentPageIdentifier !== currentPageIdentifier && currentPageIdentifier < prevState.currentPageIdentifier) {
                this.props.setCurrentPage(currentPageIdentifier);
            }

        } catch (error) {
            console.error('componentDidUpdate : error :', error);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.onPopstate, false);
    }

    /*************************************Helpers************************************* */

    verifyCompanyCode = () => {
        const { companyId } = this.state;
        const { moodleTranslationsByLanguage } = this.props;

        try {
            if (companyId === '' || typeof companyId === 'undefined' || isNaN(companyId)) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_valid_company_code']);
                return;
            }

            let params = {
                'companyCode': companyId
            };

            this.props.verifyCompanyCodeInProgress();
            this.props.verifyCompanyCode(params);

        } catch (error) {
            console.error('verifyCompanyCode : error :', error);
        }
    };

    verifyEmployeeId = () => {
        const { employeeId, companyId } = this.state;
        const { moodleTranslationsByLanguage } = this.props;

        try {
            if (employeeId === '' || typeof employeeId === 'undefined') {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_valid_employee_id']);
                return;
            }

            let params = {
                'CompCode': companyId,
                'EmployeeId': employeeId,
                'DeviceID': 0
            };

            this.props.verifyEmployeeInProgress();
            this.props.verifyEmployee(params);

        } catch (error) {
            console.error('verifyEmployeeId : error :', error);
        }
    };

    registerUser = () => {

        const { companyId, employeeId, pin, selectedQuestionId, answer, avtarId } = this.state;
        const { currentLanguage, moodleTranslationsByLanguage } = this.props;

        try {

            if (answer === '' || typeof answer === 'undefined') {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_enter_answer']);
                return;
            }

            let params = {
                CompanyCode: companyId,
                CompanyEmployeeIDNum: employeeId,
                Password: pin.join(""),
                CultureCode: currentLanguage,
                DateTimeZone: AppUtils.getBrowserTimeZone(),
                DeviceToken: '',
                DeviceType: '',
                DeviceVersion: '',
                Longitude: '',
                Latitude: '',
                AvatarId: avtarId,
                SecretQuestionId: selectedQuestionId,
                SecretQuestionAnswer: answer
            };

            this.props.registerUserInProgress();
            this.props.registerUser(params);

        } catch (error) {
            console.error('registerUser : error :', error);
        }
    };

    getActiveStepper = () => {

        const { currentPage } = this.props;
        let activeStep = 0;

        try {
            switch (currentPage) {
                case MOODLE_REGISTRATION_FLOW.COMPANY_INPUT_PAGE:
                    activeStep = 0;
                    break;

                case MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE:
                case MOODLE_REGISTRATION_FLOW.CONFIRMATION_PAGE:
                    activeStep = 1;
                    break;

                case MOODLE_REGISTRATION_FLOW.SECURITY_PIN_PAGE:
                    activeStep = 2;
                    break;

                case MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE:
                    activeStep = 3;
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.error('getActiveStepper : error :', error);
        }

        return activeStep;
    };

    /*************************************Event handlers********************************/

    handleChange = (key, value) => {
        try {
            switch (key) {
                case 'company':

                    this.setState({
                        companyId: value
                    });
                    break;

                case 'employee':

                    this.setState({
                        employeeId: value
                    });
                    break;

                case 'securityAnswer':
                    this.setState({
                        answer: value
                    });
                    break;

                case 'terms':
                    this.setState({
                        isTermsAgreed: !this.state.isTermsAgreed
                    });
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.error('handleChange : error :', error);
        }
    };

    hadleCompanyConfirmation = () => {
        try {
            /**Increase counter by 1 when user moves to next page */
            this.handleNavigationCounter();

            this.props.companyVerified();
        } catch (error) {
            console.error('hadleCompanyConfirmation : error :', error);
        }
    };

    handlePINChange = (event, index) => {

        const { pin } = this.state;
        const { moodleTranslationsByLanguage } = this.props;
        const val = event.target.value;

        try {
            if (val !== '' && isNaN(val)) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_valid_pin']);
                return;
            }

            this.setState((prevState, props) => ({
                pin: [...pin.map((d, i) => (i === index) ? val : d)]
            }));

            if (val === '') {
                AppUtils.securityPINSetFocusPrevElement(index);
            }
            else {
                AppUtils.securityPINSetFocusNextElement(index);
            }

        } catch (error) {
            console.error('handlePINChange : error :', error);
        }

    };

    handlePINConfirmation = () => {

        const { pin } = this.state;
        const { moodleTranslationsByLanguage } = this.props;

        try {
            for (let i = 0; i < pin.length; i++) {
                if (pin[i] === '') {
                    NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_full_pin']);
                    return;
                }
            }

            /**Increase counter by 1 when user moves to next page */
            this.handleNavigationCounter();

            this.props.savePIN();

        } catch (error) {
            console.error('handlePINConformation : error :', error);
        }
    };

    handleDetailsConfirmation = () => {
        try {
            /**Increase counter by 1 when user moves to next page */
            this.handleNavigationCounter();

            this.props.confirmDetails();
        } catch (error) {
            console.error('handleDetailsConfirmation : error :', error);
        }
    };

    handleQuestionChange = (value) => {
        try {
            this.setState({
                selectedQuestionId: value
            });
        } catch (error) {
            console.error('handleQuestionChange : error :', error);
        }
    };

    handleNewUser = () => {
        try {
            /**Increase counter by 1 when user moves to next page */
            this.handleNavigationCounter();

            this.props.companyCodeInputForm(true);
        } catch (error) {
            console.error('handleNewUser : error :', error);
        }
    };

    handleExistingUser = () => {
        try {
            AppUtils.loadMoodleLoginPage();
        } catch (error) {
            console.error('handleExistingUser : error :', error);
        }
    };

    handleLanguageChange = (event) => {
        try {
            const currentLanguage = event.target.value;
            this.props.changeCurrentLanguage(currentLanguage);
        } catch (error) {
            console.error('handleLanguageChange : error :', error);
        }
    };

    handleEdit = (key) => {
        try {
            switch (key) {
                case 'company':

                    /**handling navigation counter directly, for edit button */
                    this.setState({
                        currentPageIdentifier: 1
                    });

                    this.props.editCompanyCode();

                    break;

                case 'employee':

                    /**handling navigation counter directly, for edit button */
                    this.setState({
                        currentPageIdentifier: 2
                    });

                    this.props.editEmployeeId();

                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error('handleCompanyEdit : error :', error);
        }
    };

    handleEmployeeEdit = () => {
        try {
            this.props.editEmployeeId();
        } catch (error) {
            console.error('handleCompanyEdit : error :', error);
        }
    };

    handleIconButtonClick = (value) => {
        try {
            this.setState({
                avtarId: value,
                showAvtarDialog: !this.state.showAvtarDialog
            });
        } catch (error) {
            console.error('handleIconButtonClick : error :', error);
        }
    };

    toggleAvtarDialog = () => {
        try {
            this.setState({
                showAvtarDialog: !this.state.showAvtarDialog
            });
        } catch (error) {
            console.error('toggleAvtarDialog : error :', error);
        }
    };

    handleNavigationCounter = () => {

        const { currentPageIdentifier } = this.state;

        try {
            this.setState({
                currentPageIdentifier: currentPageIdentifier + 1
            });
        } catch (error) {
            console.error('handleNavigationCounter : error :', error);
        }
    };

    handleBackButton = () => {

        const { currentPageIdentifier } = this.state;

        try {
            if (currentPageIdentifier === 1) {
                AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.OPENING_PAGE);
            }
            this.setState({
                currentPageIdentifier: currentPageIdentifier - 1
            });
        } catch (error) {
            console.error('handleBackButton : error :', error);
        }
    };

    handleLoginClick = () => {
        try {
            /**Navigation counter set to 0 when user clicks on login link*/
            this.setState({
                currentPageIdentifier: 0
            });

            /**Setting history to landing page */
            AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.OPENING_PAGE);

        } catch (error) {
            console.error('handleLoginClick : error :', error);
        }
    };

    /*************************************Renders*********************************** */

    renderAvtarSelectionDialog = () => {
        const { avtarList } = this.props;
        const { showAvtarDialog } = this.state;

        return (
            <Dialog className='justify-content-center align-items-center'
                open={showAvtarDialog}
                onClose={this.toggleAvtarDialog}
            >
                <DialogContent>
                    <div className='row'>
                        {
                            avtarList && avtarList.map((item, index) => {
                                return (
                                    <IconButton
                                        key={item['id']}
                                        id={item['id']}
                                        onClick={() => this.handleIconButtonClick(item['id'])}>
                                        <Avatar
                                            src={item['azureURL']}
                                            style={{ width: 50, height: 50 }}
                                        ></Avatar>
                                    </IconButton>
                                )
                            })
                        }
                    </div>
                </DialogContent>
            </Dialog>
        )
    };

    renderAvtar = () => {

        const { avtarId } = this.state;
        const { avtarList } = this.props;

        let avtar = avtarList && avtarList.find(avtr => avtr['id'] === avtarId);

        return (
            <IconButton onClick={this.toggleAvtarDialog}>
                <Avatar
                    src={avtar && avtar['azureURL']}
                    style={{ width: 50, height: 50 }}
                ></Avatar>
            </IconButton>
        )
    };

    renderLSLogo = () => {

        const { currentPage } = this.props;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.LANDING_PAGE &&
            <div className='row m-2 justify-content-center'>
                <div className='ls_logo_container laborsolution_logo'></div>
            </div>
        )
    };

    renderLoader = () => {
        const {
            verifyCompanyStatus,
            verifyEmployeeStatus,
            registerUserStatus,
            securityQuestionsFetchStatus,
            supportingLanguagesFetchStatus,
            atarFetchStatus
        } = this.props;

        return (
            (verifyCompanyStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                verifyEmployeeStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                registerUserStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                securityQuestionsFetchStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                supportingLanguagesFetchStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                atarFetchStatus === HTTP_REQUEST_STATUS.IN_PROGRESS) &&
            AppUtils.getLoader()
        )
    };

    renderConfirmationDialog = () => {
        const { companyName, showConfirmationDialog, moodleTranslationsByLanguage } = this.props;

        return (
            <Dialog
                open={showConfirmationDialog}
                onClose={() => this.props.toggleDialog(false)}
            >
                <DialogTitle className='d-flex justify-content-center'>
                    <h6>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_confirmation_dialog_text']}</h6>
                </DialogTitle>
                <DialogContent className='d-flex justify-content-center'>
                    <h3>{companyName}</h3>
                </DialogContent>
                <DialogActions className='mb-2 justify-content-center'>
                    {
                        AppUtils.getFormComponent({
                            'type': 'button',
                            'variant': 'contained',
                            'handleButtonClick': () => this.props.toggleDialog(false),
                            'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_no_button_label'],
                            'style': commonStyles.button_white,
                            'size': 'small'
                        })
                    }
                    {
                        AppUtils.getFormComponent({
                            'type': 'button',
                            'variant': 'contained',
                            'handleButtonClick': this.hadleCompanyConfirmation,
                            'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_yes_button_label'],
                            'style': commonStyles.button_active,
                            'size': 'small'
                        })
                    }
                </DialogActions>
            </Dialog>
        )
    };

    renderPrivacyPolicyMessage = () => {

        const { moodleTranslationsByLanguage } = this.props;
        const { isTermsAgreed } = this.state;

        return (
            <div className='row pl-1'>
                <div className='policy_note d-inline'>
                    <Checkbox
                        size='small'
                        checked={isTermsAgreed}
                        color='primary'
                        onChange={() => this.handleChange('terms')}
                    />
                    {moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_terms_conditions']}
                    <a style={{ color: { PRIMARY_COLOR_BLUE } }} href={privacyPolicyUrl} target='_blank' rel="noopener noreferrer">  {moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_terms_conditions_link']}</a>
                </div>
            </div>
        )
    };

    renderEditButton = (detail) => {
        return (
            <Button
                style={{ background: '#FFFFFF', float: 'right' }}
                onClick={() => this.handleEdit(detail)}
            >
                <div className='edit_icon edit_button_container'>
                </div>
            </Button>
        )
    };

    renderEmployeeInputForm = () => {
        const { employeeId } = this.state;
        const { companyName, currentPage, moodleTranslationsByLanguage } = this.props;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE &&
            <>
                <h1 className='heading_note mb-4'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_register_label']}</h1>
                {this.renderLoader()}
                <h6 className='description'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_your_company_text']}</h6>
                <div className='row mb-4'>
                    <div className='col-8'>
                        <h6 style={{ fontWeight: 'bold' }}>{companyName}</h6>
                    </div>
                    <div className='col-4'>
                        {this.renderEditButton('company')}
                    </div>
                </div>
                <h6 className='description'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_employee_desc']}</h6>
                <form autoComplete='off'>
                    <fieldset>
                        {
                            AppUtils.getFormComponent({
                                'key': 'employee',
                                'type': "custom_style_textfield",
                                'changeCallback': this.handleChange,
                                'value': employeeId,
                                //'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_employee_desc'],
                                'InputLabelProps': {
                                    style: {
                                        fontSize: 14,
                                        color: SUB_NOTE_GREAY
                                    }
                                },
                                'variant': 'outlined',
                                'size': 'small',
                                'style': { marginTop: '0px' },
                                'placeholder': 'ex: 12345'
                            })
                        }
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {
                                AppUtils.getFormComponent({
                                    'type': 'button',
                                    'variant': 'contained',
                                    'fullWidth': true,
                                    'handleButtonClick': this.verifyEmployeeId,
                                    'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_button_next'],
                                    'style': commonStyles.button_active
                                })
                            }
                        </div>
                    </fieldset>
                </form>
            </>
        )
    };

    renderConfirmationForm = () => {
        const { employeeId } = this.state;
        const { companyName, currentPage, moodleTranslationsByLanguage } = this.props;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.CONFIRMATION_PAGE &&
            <>
                <h1 className='heading_note mb-4'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_details_info']}</h1>
                {this.renderLoader()}
                <div className='row mb-2 mt-2 align-items-center'>
                    <div className='col-3'>
                        {this.renderAvtar()}
                    </div>
                    <div className='col-9'>
                        <h6>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_your_company_text']}</h6>
                        <div className='row justify-content-start align-items-center'>
                            <div className='col-8'>
                                <h6 style={{ fontWeight: 'bold' }}>{companyName}</h6>
                            </div>
                            <div className='col-4'>
                                {this.renderEditButton('company')}
                            </div>
                        </div>
                        <h6>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_your_employee_id']}</h6>
                        <div className='row justify-content-start align-items-center'>
                            <div className='col-8'>
                                <h6 style={{ fontWeight: 'bold' }}>{employeeId}</h6>
                            </div>
                            <div className='col-4'>
                                {this.renderEditButton('employee')}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mb-3 d-flex align-items-center justify-content-between">
                    {
                        AppUtils.getFormComponent({
                            'type': 'button',
                            'variant': 'contained',
                            'fullWidth': true,
                            'handleButtonClick': this.handleDetailsConfirmation,
                            'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_confime_button'],
                            'style': commonStyles.button_active
                        })
                    }
                </div>
            </>
        )
    };

    renderCompanyInputForm = () => {

        const { companyId, isTermsAgreed } = this.state;
        const { currentPage, moodleTranslationsByLanguage } = this.props;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.COMPANY_INPUT_PAGE &&
            <>
                <h1 className='heading_note mb-4'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_register_label']}</h1>
                {this.renderLoader()}
                <h6 className='description'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_register_desc']}</h6>
                <form autoComplete='off'>
                    <fieldset>
                        {
                            AppUtils.getFormComponent({
                                'key': 'company',
                                'type': "custom_style_textfield",
                                'changeCallback': this.handleChange,
                                'value': companyId,
                                //'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_register_desc'],
                                'InputLabelProps': {
                                    style: {
                                        fontSize: 14,
                                        color: SUB_NOTE_GREAY
                                    }
                                },
                                'variant': 'outlined',
                                'size': 'small',
                                'style': { marginTop: '0px' },
                                'placeholder': 'ex: 12345'
                            })
                        }
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {
                                AppUtils.getFormComponent({
                                    'type': 'button',
                                    'variant': 'contained',
                                    'fullWidth': true,
                                    'handleButtonClick': this.verifyCompanyCode,
                                    'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_button_next'],
                                    'style': commonStyles.button_active,
                                    'disabled': !isTermsAgreed
                                })
                            }
                        </div>
                    </fieldset>
                </form>
                {this.renderPrivacyPolicyMessage()}
            </>
        )
    };

    renderLadingPageActionButtons = () => {

        const { currentPage, moodleTranslationsByLanguage } = this.props;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.LANDING_PAGE &&
            <>
                {this.renderLoader()}
                <div style={{ marginBottom: '1rem' }}>
                    {
                        AppUtils.getFormComponent({
                            'type': 'button',
                            'variant': 'contained',
                            'fullWidth': true,
                            'handleButtonClick': this.handleExistingUser,
                            'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_existing_user_button_label'],
                            'style': commonStyles.button_active
                        })
                    }
                </div>

                {
                    AppUtils.getFormComponent({
                        'type': 'button',
                        'variant': 'contained',
                        'fullWidth': true,
                        'handleButtonClick': this.handleNewUser,
                        'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_new_user_button_label'],
                        'style': commonStyles.button_white
                    })
                }
            </>
        )
    };

    renderSecurityQuestionForm = () => {
        const { currentPage, securityQuestionsLanguageMap, currentLanguage, moodleTranslationsByLanguage } = this.props;
        const { answer, selectedQuestionId } = this.state;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE &&
            <>
                <h1 className='heading_note'> {moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_security_question']}</h1>
                {this.renderLoader()}
                <h6 style={{ color: SUB_NOTE_GREAY }}> {moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_choose_question']}</h6>
                <form autoComplete='off'>
                    <fieldset>
                        <Select
                            style={{ marginBottom: 10, height: '2.1876em' }}
                            value={selectedQuestionId}
                            onChange={(e) => this.handleQuestionChange(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size='small'
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right"
                                },
                                getContentAnchorEl: null,
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                }
                            }}
                        >
                            {securityQuestionsLanguageMap && securityQuestionsLanguageMap[currentLanguage] &&
                                securityQuestionsLanguageMap[currentLanguage].map((option) => (
                                    <MenuItem key={option.key} value={option.value}>
                                        <img src={option['azureURL']} alt='' style={{ width: 20, marginRight: 5, justifyContent: 'center' }} />
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </Select>

                        {
                            AppUtils.getFormComponent({
                                'key': 'securityAnswer',
                                'type': "custom_style_textfield",
                                'changeCallback': this.handleChange,
                                'value': answer,
                                'placeholder': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_your_answer'],
                                'InputLabelProps': {
                                    style: {
                                        fontSize: 14,
                                        color: SUB_NOTE_GREAY
                                    }
                                },
                                'variant': 'outlined',
                                'size': 'small',
                                'style': { marginTop: '2px' }
                            })
                        }
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {
                                AppUtils.getFormComponent({
                                    'type': 'button',
                                    'variant': 'contained',
                                    'fullWidth': true,
                                    'handleButtonClick': this.registerUser,
                                    'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_button_next'],
                                    'style': commonStyles.button_active
                                })
                            }
                        </div>

                    </fieldset>
                </form>
            </>
        )
    };

    renderSecurityPINPage = () => {

        const { currentPage, moodleTranslationsByLanguage } = this.props;
        const { pin } = this.state;

        return (
            currentPage === MOODLE_REGISTRATION_FLOW.SECURITY_PIN_PAGE &&
            <SecurityPINPage
                pin={pin}
                note={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_pin_code']}
                description={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_enter_pin_code']}
                handlePINChange={this.handlePINChange}
                handlePINConfirmation={this.handlePINConfirmation}
                moodleTranslationsByLanguage={moodleTranslationsByLanguage}
            />
        )

    }

    renderHeader = () => {

        const { currentPage, moodleTranslationsByLanguage } = this.props;

        return (
            <HeaderNote currentPage={currentPage} moodleTranslationsByLanguage={moodleTranslationsByLanguage} />
        )
    };

    renderStepper = () => {

        let activeStep = this.getActiveStepper();
        const { currentPage } = this.props;
        const { maxSteps } = this.state;

        return (
            currentPage !== MOODLE_REGISTRATION_FLOW.LANDING_PAGE &&
            <div className='row m-2 justify-content-end'>
                <MobileStepper
                    variant='dots'
                    steps={maxSteps}
                    activeStep={activeStep}
                    position="static" />
            </div>
        )
    };

    renderFooter = () => {

        const { currentPage, moodleTranslationsByLanguage } = this.props;

        return (
            <FooterNote
                currentPage={currentPage}
                moodleTranslationsByLanguage={moodleTranslationsByLanguage}
                handleClick={this.handleLoginClick}
            />
        )
    };

    renderSuccessDialog = () => {
        const { showSuccessDialog, moodleTranslationsByLanguage } = this.props;
        return (
            <ConfirmationDialog
                showDialog={showSuccessDialog}
                message={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_account_created']}
                logo='success_logo'
            />
        )
    };

    renderWelcomeDialog = () => {
        const { showWelcomeDialog, moodleTranslationsByLanguage } = this.props;
        return (
            <ConfirmationDialog
                showDialog={showWelcomeDialog}
                message={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_welcome_back']}
                logo='simple_wovo_logo'
            />
        )
    };

    renderHeaderLogoComponent = () => {

        const { supportingLanguages, currentLanguage } = this.props;

        return (
            <HeaderLogoComponent
                supportingLanguages={supportingLanguages}
                selectedLanguage={currentLanguage}
                handleLanguageChange={this.handleLanguageChange}
            />
        )
    };

    renderCenterImageDisplay = () => {

        const { currentPage } = this.props;
        return (
            <CenterImageDisplay
                currentPage={currentPage} />
        )
    };

    renderNotificaionContainer = () => <NotificationContainer />

    render() {
        return (
            <div className='container-fluid'>
                <div className='row h-100'>
                    <div className='col-3 left_panel'>
                        <div className='h-100 d-flex flex-column'>
                            {this.renderHeader()}
                            {this.renderStepper()}
                            <div className='row m-2 button_container'>
                                <div className='col'>
                                    {this.renderCompanyInputForm()}
                                    {this.renderEmployeeInputForm()}
                                    {this.renderConfirmationForm()}
                                    {this.renderSecurityPINPage()}
                                    {this.renderSecurityQuestionForm()}
                                    {this.renderLadingPageActionButtons()}
                                </div>
                            </div>
                            {this.renderFooter()}
                            {this.renderLSLogo()}
                        </div>
                    </div>
                    <div className='col-9 image_container'>
                        {this.renderConfirmationDialog()}
                        {this.renderSuccessDialog()}
                        {this.renderWelcomeDialog()}
                        {this.renderHeaderLogoComponent()}
                        {this.renderCenterImageDisplay()}
                        {this.renderAvtarSelectionDialog()}
                    </div>
                    {this.renderNotificaionContainer()}
                </div>
            </div>
        )
    };
}

const commonStyles = {
    button_active: {
        color: '#FFFFFF',
        background: '#2196F3'
    },
    button_white: {
        color: '#2196F3',
        background: '#FFFFFF'
    }
};

const mapStateToProps = ({ ReducerMoodleRegistration, ReducerMoodleResources }) => {

    const {
        companyName,
        verifyCompanyStatus,
        showConfirmationDialog,
        verifyEmployeeStatus,
        isValidUser,
        currentPage,
        securityQuestionsFetchStatus,
        securityQuestionsLanguageMap,
        fetchSecurityQuestions,
        registerUserStatus,
        showSuccessDialog,
        showWelcomeDialog,
        atarFetchStatus,
        avtarList,
        isCompanyValid
    } = ReducerMoodleRegistration;

    const {
        supportingLanguages,
        supportingLanguagesFetchStatus,
        moodleTranslations,
        translaionsFetchStatus,
        currentLanguage,
        moodleTranslationsByLanguage
    } = ReducerMoodleResources;

    return {
        companyName,
        verifyCompanyStatus,
        showConfirmationDialog,
        verifyEmployeeStatus,
        isValidUser,
        currentPage,
        securityQuestionsFetchStatus,
        securityQuestionsLanguageMap,
        fetchSecurityQuestions,
        registerUserStatus,
        showSuccessDialog,
        showWelcomeDialog,
        supportingLanguages,
        supportingLanguagesFetchStatus,
        currentLanguage,
        moodleTranslations,
        translaionsFetchStatus,
        moodleTranslationsByLanguage,
        atarFetchStatus,
        avtarList,
        isCompanyValid
    }
}

export default connect(mapStateToProps, {
    companyCodeInputForm,
    verifyCompanyCode,
    toggleDialog,
    companyVerified,
    verifyCompanyCodeInProgress,
    verifyEmployee,
    verifyEmployeeInProgress,
    verifyEmployeeNone,
    verifyCompanyCodeNone,
    confirmDetails,
    savePIN,
    getSecurityQuestions,
    getSecurityQuestionsNone,
    registerUser,
    registerUserInProgress,
    registerUserNone,
    hideSuccessDialog,
    hideWelcomeDialog,
    getSupportingLanguagesInProgress,
    getSupportingLanguages,
    getSupportingLanguagesNone,
    changeCurrentLanguage,
    getTranslations,
    getTranslationsNone,
    editCompanyCode,
    editEmployeeId,
    getAvtarListInProgress,
    getAvtarList,
    getAvtarListNone,
    setCurrentPage
})(MoodleRegistration);