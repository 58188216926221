import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { wovoAPIBaseUrl } from "../../util/keys";
import {
    URL_MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES,
    URL_MOODLE_GET_TRANSLATIONS
} from '../../constants/urls';
import {
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES,
    MOODLE_REGISTRATION_GET_TRANSLATIONS
} from '../../constants/actions/moodleRegistration/actionTypesMoodleRegistration';
import {
    getTranslationsError,
    getTranslationsSuccess,    
    getSupportingLanguagesSuccess,
    getSupportingLanguagesError
} from '../../actions/moodleRegistration/actionMoodleResources';
import { HTTP_STATUS_CODE } from "../../constants/config";

/************ methods : http *******************/

const httpGetSupportingLanguages = async () => {
    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES)
        .then(data => data)
        .catch(error => {
            return error
        });
}

const httpGetTranslations = async () => {
    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_GET_TRANSLATIONS)
        .then(data => data)
        .catch(error => {
            return error
        });
}

/************ methods : http response handler *******************/

function* handleHttpGetSupportingLanguages() {
    try {
        let dataValue = yield call(httpGetSupportingLanguages);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(getSupportingLanguagesSuccess(dataValue));
        } else {
            yield put(getSupportingLanguagesError(dataValue.message));
        }

    } catch (error) {
        yield put(getSupportingLanguagesError(error));
    }
}

function* handleHttpGetTranslations() {
    try {
        let dataValue = yield call(httpGetTranslations);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(getTranslationsSuccess(dataValue));
        } else {
            yield put(getTranslationsError(dataValue.message));
        }

    } catch (error) {
        yield put(getTranslationsError(error));
    }
}

/************ methods : export access *******************/

export function* getSupportingLanguages() {
    yield takeEvery(MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES, handleHttpGetSupportingLanguages)
}

export function* getTranslations() {
    yield takeEvery(MOODLE_REGISTRATION_GET_TRANSLATIONS, handleHttpGetTranslations)
}

export default function* rootSaga() {
    yield all([
        fork(getSupportingLanguages),
        fork(getTranslations)
    ]);
}