import {
    MOODLE_REGISTRATION_FLOW,
    MOODLE_RESET_PIN_FLOW,
    VALIDATE_USER_RESPONSE_TYPES,
    MOODLE_REGISTRATION_NAVIGATION_MAPPING,
    MOODLE_REGISTRATION_PATH_NAME
} from '../../constants/config';
import { HTTP_REQUEST_STATUS } from '../../constants/constants';
import {
    MOODLE_REGISTRATION_NEW_USER,

    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_ERROR,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_SUCCESS,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_TOGGLE_DIALOG,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_VERIFIED,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_INPROGRESS,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_NONE,


    MOODLE_REGISTRATION_EDIT_COMPANY_CODE,
    MOODLE_REGISTRATION_EDIT_EMPLOYEE_ID,

    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_ERROR,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_SUCCESS,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_INPROGRESS,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_NONE,

    MOODLE_REGISTRATION_CONFIRM_DETAILS,

    MOODLE_REGISTRATION_SAVE_PIN,

    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_SUCCESS,
    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_ERROR,
    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_NONE,

    MOODLE_REGISTRATION_REGISTER_USER_SUCCESS,
    MOODLE_REGISTRATION_REGISTER_USER_ERROR,
    MOODLE_REGISTRATION_REGISTER_USER_NONE,
    MOODLE_REGISTRATION_REGISTER_USER_IN_PROGRESS,

    MOODLE_REGISTRATION_HIDE_SUCCESS_DIALOG,
    MOODLE_REGISTRATION_HIDE_WELCOME_DIALOG,

    MOODLE_REGISTRATION_GET_AVTAR_IN_PROGRESS,
    MOODLE_REGISTRATION_GET_AVTAR_SUCCESS,
    MOODLE_REGISTRATION_GET_AVTAR_ERROR,
    MOODLE_REGISTRATION_GET_AVTAR_NONE,

    MOODLE_REGISTRATION_NAVIGATION
} from '../../constants/actions/moodleRegistration/actionTypesMoodleRegistration';
import AppUtils from '../../util/util';

const intialState = {
    isNewUser: false,
    isCompanyValid: true,
    companyName: '',
    alertMessage: '',
    isCompanyCodeVerified: false,
    showConfirmationDialog: false,
    verifyCompanyStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    verifyEmployeeStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    currentPage: MOODLE_REGISTRATION_FLOW.LANDING_PAGE,
    currenResetPinPage: MOODLE_RESET_PIN_FLOW.ACCOUNT_DETAILS_PAGE,
    isValidUser: true,
    fetchSecurityQuestions: false,
    securityQuestionsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    securityQuestionsLanguageMap: {},
    registerUserStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    showSuccessDialog: false,
    showWelcomeDialog: false,
    atarFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    avtarList: []
};

const ReducerMoodleRegistration = (state = intialState, action) => {

    switch (action.type) {

        case MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_SUCCESS: {

            let responseStatus = '';
            let isCompanyValid = true;
            let companyName = '';
            let showConfirmationDialog = false;

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {

                responseStatus = action.payload.data['responseStatus'];

                if (responseStatus === VALIDATE_USER_RESPONSE_TYPES['INVALID_COMPANY']) {
                    isCompanyValid = false;
                }
                else {
                    companyName = responseStatus;
                    showConfirmationDialog = true;
                }
            }
            return {
                ...state,
                companyName: companyName,
                verifyCompanyStatus: HTTP_REQUEST_STATUS.SUCCESS,
                showConfirmationDialog: showConfirmationDialog,
                isCompanyValid: isCompanyValid
            }
        }

        case MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_ERROR: {

            return {
                ...state,
                verifyCompanyStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_INPROGRESS: {
            return {
                ...state,
                verifyCompanyStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_NONE: {
            return {
                ...state,
                verifyCompanyStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_TOGGLE_DIALOG: {
            return {
                ...state,
                showConfirmationDialog: action.payload
            }
        }

        case MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_VERIFIED: {

            let currentPage = MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE;

            if (!state.isNewUser) {
                currentPage = MOODLE_REGISTRATION_FLOW.LOGIN_PAGE;
            } else {
                /**Adding navigation to browser history to handle back press */
                AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.EMPLOYEE_PAGE);
            }
            return {
                ...state,
                showConfirmationDialog: false,
                currentPage: currentPage
            }
        }

        case MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_SUCCESS: {

            let currentPage = '';
            let validUser = true;
            let showWelcomeDialog = false;

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {

                let responseStatus = '';

                if ('responseStatus' in action.payload.data) {
                    responseStatus = action.payload.data['responseStatus'];
                }
                else if ('companyEmployeeIDNum' in action.payload.data) {
                    responseStatus = VALIDATE_USER_RESPONSE_TYPES['VALID_EMPLOYEE_ID'];
                }

                if (responseStatus === VALIDATE_USER_RESPONSE_TYPES['REGISTERED_AND_MAPPED']) {
                    currentPage = MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE;
                    showWelcomeDialog = true;
                }
                else if (responseStatus === VALIDATE_USER_RESPONSE_TYPES['INVALID_EMPLOYEE']) {
                    currentPage = MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE;
                    validUser = false;
                }
                else if (responseStatus === VALIDATE_USER_RESPONSE_TYPES['NON_REGISTERED'] ||
                    responseStatus === VALIDATE_USER_RESPONSE_TYPES['VALID_EMPLOYEE_ID'] ||
                    responseStatus === VALIDATE_USER_RESPONSE_TYPES['REGISTERED_AND_NOT_MAPPED']) {

                    currentPage = MOODLE_REGISTRATION_FLOW.CONFIRMATION_PAGE;
                    validUser = true;

                    /**Adding navigation to browser history to handle back press */
                    AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.CONFIRMATION_PAGE);
                }
                else {
                    currentPage = MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE;
                    validUser = false;
                }
            }
            return {
                ...state,
                verifyEmployeeStatus: HTTP_REQUEST_STATUS.SUCCESS,
                currentPage: currentPage,
                isValidUser: validUser,
                showWelcomeDialog: showWelcomeDialog
            }
        }

        case MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_ERROR: {
            return {
                ...state,
                verifyEmployeeStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_INPROGRESS: {
            return {
                ...state,
                verifyEmployeeStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_NONE: {
            return {
                ...state,
                verifyEmployeeStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                isValidUser: true
            }
        }

        case MOODLE_REGISTRATION_CONFIRM_DETAILS: {

            /**Adding navigation to browser history to handle back press */
            AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.SECURITY_PIN_PAGE);

            return {
                ...state,
                currentPage: MOODLE_REGISTRATION_FLOW.SECURITY_PIN_PAGE
            }
        }

        case MOODLE_REGISTRATION_NEW_USER: {

            /**Adding navigation to browser history to handle back press */
            AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.COMPANY_PAGE);

            return {
                ...state,
                currentPage: MOODLE_REGISTRATION_FLOW.COMPANY_INPUT_PAGE,
                isNewUser: action.payload
            }
        }

        case MOODLE_REGISTRATION_SAVE_PIN: {

            /**Adding navigation to browser history to handle back press */
            AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.SECURITY_QUESTION_PAGE);

            return {
                ...state,
                currentPage: MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE,
                fetchSecurityQuestions: true
            }
        }

        case MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_SUCCESS: {

            let securityQuestionsLanguageMap = {};

            if (typeof action.payload != 'undefined' && typeof action.payload.data != 'undefined') {
                securityQuestionsLanguageMap = getSecurityQuestionLanguageMap(action.payload.data);
            }

            return {
                ...state,
                securityQuestionsLanguageMap: securityQuestionsLanguageMap,
                securityQuestionsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                currentPage: MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE,
                fetchSecurityQuestions: false
            }
        }

        case MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_ERROR: {

            return {
                ...state,
                securityQuestionsFetchStatus: HTTP_REQUEST_STATUS.FAIL,
                currentPage: MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE,
                fetchSecurityQuestions: false
            }
        }

        case MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_NONE: {

            return {
                ...state,
                securityQuestionsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_REGISTRATION_REGISTER_USER_IN_PROGRESS: {

            return {
                ...state,
                registerUserStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_REGISTRATION_REGISTER_USER_ERROR: {
            return {
                ...state,
                registerUserStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_REGISTRATION_REGISTER_USER_NONE: {
            return {
                ...state,
                registerUserStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_REGISTRATION_REGISTER_USER_SUCCESS: {
            let showSuccessDialog = false;

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {

                let responseStatus = action.payload.data['responseStatus'];

                if (responseStatus === VALIDATE_USER_RESPONSE_TYPES['REGISTERED_USER']) {

                    /**Adding navigation to browser history to handle back press */
                    AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.OPENING_PAGE);

                    showSuccessDialog = true;
                }
            }
            return {
                ...state,
                showSuccessDialog: showSuccessDialog,
                registerUserStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case MOODLE_REGISTRATION_HIDE_SUCCESS_DIALOG: {
            return {
                ...state,
                showSuccessDialog: false
            }
        }

        case MOODLE_REGISTRATION_HIDE_WELCOME_DIALOG: {
            return {
                ...state,
                showWelcomeDialog: false
            }
        }

        case MOODLE_REGISTRATION_EDIT_COMPANY_CODE: {

            /**Adding navigation to browser history to handle back press */
            AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.COMPANY_PAGE);

            return {
                ...state,
                currentPage: MOODLE_REGISTRATION_FLOW.COMPANY_INPUT_PAGE
            }
        }

        case MOODLE_REGISTRATION_EDIT_EMPLOYEE_ID: {

            /**Adding navigation to browser history to handle back press */
            AppUtils.pushNavigationHistory(MOODLE_REGISTRATION_PATH_NAME.EMPLOYEE_PAGE);

            return {
                ...state,
                currentPage: MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE
            }
        }

        case MOODLE_REGISTRATION_GET_AVTAR_IN_PROGRESS: {

            return {
                ...state,
                atarFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_REGISTRATION_GET_AVTAR_ERROR: {
            return {
                ...state,
                atarFetchStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_REGISTRATION_GET_AVTAR_NONE: {
            return {
                ...state,
                atarFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_REGISTRATION_GET_AVTAR_SUCCESS: {
            let avtarList = [];

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {
                avtarList = getavtarList(action.payload.data);
            }
            return {
                ...state,
                avtarList: avtarList,
                atarFetchStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case MOODLE_REGISTRATION_NAVIGATION: {
            return {
                ...state,
                currentPage: MOODLE_REGISTRATION_NAVIGATION_MAPPING[action.payload]
            }
        }

        default:
            return state;
    }

};

function getavtarList(data) {

    let result = [];

    try {
        if (typeof data !== 'undefined' && data.length > 0) {

            for (let i = 0; i < data.length; i++) {

                let avtar = {};
                avtar['value'] = data[i]['id'];
                avtar['id'] = data[i]['id'];
                avtar['azureURL'] = data[i]['azureURL'];
                avtar['name'] = data[i]['name'];


                result.push(avtar);
            }
        }
    } catch (error) {
        console.error('getavtarList : error :', error);
    }
    return result;
}

function getSecurityQuestionLanguageMap(data) {
    let questionLanguageMap = {};

    try {
        if (typeof data !== 'undefined' && data.length > 0) {

            for (let i = 0; i < data.length; i++) {

                let key = data[i]['cultureCodeId'];

                let question = {};

                question['key'] = data[i]['id'];
                question['value'] = data[i]['id'];
                question['azureURL'] = data[i]['azureURL'];
                question['label'] = data[i]['questionText'];
                question['cultureCodeId'] = data[i]['cultureCodeId'];

                if (key in questionLanguageMap) {
                    questionLanguageMap[key].push(question);
                } else {
                    questionLanguageMap[key] = [];
                    questionLanguageMap[key].push(question);
                }
            }
        }
    } catch (error) {
        console.error('getSecurityQuestionLanguageMap : error :', error);
    }

    return questionLanguageMap;
}

export default ReducerMoodleRegistration;