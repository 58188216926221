import React from 'react';
import { connect } from 'react-redux';
import CenterImageDisplay from '../components/moodleRegistration/CenterImageComponent';
import HeaderLogoComponent from '../components/moodleRegistration/HeaderAndSupportLanguageComponent';
import {
    getSupportingLanguages,
    getSupportingLanguagesInProgress,
    getSupportingLanguagesNone,
    getTranslations,
    getTranslationsNone,
    getTranslationsInProgress,
    changeCurrentLanguage
} from '../actions/moodleRegistration/actionMoodleResources';
import {
    validateUserDetailsInProgress,
    validateUserDetailsNone,
    validateUserDetails,
    answerVerified,
    resetPINInProgress,
    resetPIN,
    resetPINNone,
    resetPINHideSuccessDialog,
    setCurrentPageInPINFlow
} from '../actions/moodleRegistration/actionMoodleResetPin';
import { HTTP_REQUEST_STATUS } from '../constants/constants';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { MOODLE_RESET_PIN_FLOW, MOODLE_RESET_PIN_PATH_NAME, PIN_SIZE } from '../constants/config';
import HeaderNote from '../components/moodleRegistration/HeaderNote';
import FooterNote from '../components/moodleRegistration/FooterNote';
import SecurityPINPage from '../components/moodleRegistration/SecurityPINPage';
import ConfirmationDialog from '../components/moodleRegistration/ConfirmationDialog';
import AppUtils from '../util/util';
import { SUB_NOTE_GREAY } from '../constants/customUIConfig';

class ResetPin extends React.Component {

    constructor() {
        super();

        this.state = {
            companyCode: '',
            employeeId: '',
            answer: '',
            newPin: new Array(PIN_SIZE).fill(""),
            currentPageIdentifier: 0
        }
    };

    componentDidMount() {
        try {
            this.props.getTranslationsInProgress();
            this.props.getTranslations();

            this.props.getSupportingLanguagesInProgress();
            this.props.getSupportingLanguages();

            /**Adding Event listener for back button press */
            window.addEventListener('popstate', this.handleBackButton);

        } catch (error) {
            console.error('componentDidMount : error :', error);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            supportingLanguagesFetchStatus,
            validateUserDetailsStatus,
            isUserDetailsValid,
            resetPINStatus,
            showPINResetSuccessDalog,
            isPINUpdated,
            translaionsFetchStatus,
            moodleTranslationsByLanguage
        } = this.props;

        const { currentPageIdentifier } = this.state;

        try {

            let alertMessage = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_fail'];

            if (supportingLanguagesFetchStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.getSupportingLanguagesNone();
            }

            if (validateUserDetailsStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.validateUserDetailsNone();
            }

            if (validateUserDetailsStatus === HTTP_REQUEST_STATUS.SUCCESS && !isUserDetailsValid) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_invalid_user']);
                this.props.validateUserDetailsNone();
            }

            if (validateUserDetailsStatus === HTTP_REQUEST_STATUS.SUCCESS && isUserDetailsValid) {
                /**Increase counter by 1 when user moves to next page */
                this.handleNavigationCounter();

                this.props.validateUserDetailsNone();
            }

            if (resetPINStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.resetPINNone();
            }

            if (resetPINStatus === HTTP_REQUEST_STATUS.SUCCESS && !isPINUpdated) {
                NotificationManager.error(alertMessage);
                this.props.resetPINNone();
            }

            if (translaionsFetchStatus === HTTP_REQUEST_STATUS.FAIL) {
                NotificationManager.error(alertMessage);
                this.props.getTranslationsNone();
            }

            if (showPINResetSuccessDalog) {
                setTimeout(() => {
                    this.props.resetPINHideSuccessDialog();
                    AppUtils.loadMoodleLoginPage();
                }, 2000);
            }

            if (prevState.currentPageIdentifier !== currentPageIdentifier && currentPageIdentifier < prevState.currentPageIdentifier) {
                this.props.setCurrentPageInPINFlow(currentPageIdentifier);
            }
        } catch (error) {
            console.error('componentDidUpdate : error :', error);
        }
    }

    /******************************************Helpers************************************************ */

    validateUserDetails = () => {
        const { companyCode, employeeId } = this.state;
        const { moodleTranslationsByLanguage, currentLanguage } = this.props;

        try {
            if (companyCode === '' || typeof companyCode === 'undefined' || isNaN(companyCode)) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_valid_company_code']);
                return;
            }

            if (employeeId === '' || typeof employeeId === 'undefined') {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_valid_employee_id']);
                return;
            }

            let params = {
                'CompCode': companyCode,
                'EmployeeId': employeeId,
                'cultureCodeId': currentLanguage
            };

            this.props.validateUserDetailsInProgress();
            this.props.validateUserDetails(params);

        } catch (error) {
            console.error('validateUserDetails : error :', error);
        }
    };

    verifyAnswer = () => {

        const { answer } = this.state;
        const { userResetPinDetails, moodleTranslationsByLanguage } = this.props;

        try {
            if (answer === '' || typeof answer === 'undefined') {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_enter_answer']);
                return;
            }

            let isValidAnswer = false;

            if (typeof userResetPinDetails !== 'undefined' && typeof userResetPinDetails['secretQuestionAnswer'] !== 'undefined') {
                isValidAnswer = answer === userResetPinDetails['secretQuestionAnswer'];
            }

            if (isValidAnswer) {
                /**Increase counter by 1 when user moves to next page */
                this.handleNavigationCounter();

                this.props.answerVerified();
            } else {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_answer_not_matching']);
                return;
            }
        } catch (error) {
            console.error('verifyAnswer : error :', error);
        }
    };

    getItemValue = (key) => {

        let value = '';

        try {
            switch (key) {
                case 'company':
                    value = this.state.companyCode;
                    break;

                case 'employee':
                    value = this.state.employeeId;
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.error('getItemValue : error :', error);
        }

        return value;
    };

    getItemLabel = (key) => {

        const { moodleTranslationsByLanguage } = this.props;

        let label = '';

        try {
            switch (key) {
                case 'company':
                    label = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_company_code'];
                    break;

                case 'employee':
                    label = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_employee_id'];
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.error('getItemLabel : error :', error);
        }

        return label;
    };

    /*************************************Event handlers**************************************************/

    handleChange = (key, value) => {
        try {
            switch (key) {
                case 'company':

                    this.setState({
                        companyCode: value
                    });
                    break;

                case 'employee':

                    this.setState({
                        employeeId: value
                    });
                    break;

                case 'securityAnswer':
                    this.setState({
                        answer: value
                    });
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.error('handleChange : error :', error);
        }
    };

    handlePINChange = (event, index) => {

        const { newPin } = this.state;
        const { moodleTranslationsByLanguage } = this.props;
        const val = event.target.value;

        try {
            if (val !== '' && isNaN(val)) {
                NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_valid_pin']);
                return;
            }

            this.setState({
                newPin: [...newPin.map((d, i) => (i === index) ? val : d)]
            });


            if (val === '') {
                AppUtils.securityPINSetFocusPrevElement(index);
            }
            else {
                AppUtils.securityPINSetFocusNextElement(index);
            }

        } catch (error) {
            console.error('handlePINChange : error :', error);
        }
    };

    handlePINConfirmation = () => {

        const { newPin, companyCode, employeeId } = this.state
        const { moodleTranslationsByLanguage } = this.props;

        try {
            for (let i = 0; i < newPin.length; i++) {
                if (newPin[i] === '') {
                    NotificationManager.error(moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_full_pin']);
                    return;
                }
            }

            let params = {
                PIN: newPin.join(""),
                companyCode: companyCode,
                employeeId: employeeId
            };

            this.props.resetPINInProgress();
            this.props.resetPIN(params);

        } catch (error) {
            console.error('handlePINConfirmation : error :', error);
        }
    };

    handleLanguageChange = (event) => {
        const currentLanguage = event.target.value;
        this.props.changeCurrentLanguage(currentLanguage);
    };

    handleNavigationCounter = () => {

        const { currentPageIdentifier } = this.state;

        try {
            this.setState({
                currentPageIdentifier: currentPageIdentifier + 1
            });
        } catch (error) {
            console.error('handleNavigationCounter : error :', error);
        }
    };

    handleBackButton = () => {

        const { currentPageIdentifier } = this.state;

        try {
            if (currentPageIdentifier === 1) {
                AppUtils.pushNavigationHistory(MOODLE_RESET_PIN_PATH_NAME.OPENING_PAGE);
            }
            this.setState({
                currentPageIdentifier: currentPageIdentifier - 1
            });
        } catch (error) {
            console.error('handleBackButton : error :', error);
        }
    };

    handleLoginClick = () => {
        try {
            /**Navigation counter set to 0 when user clicks on login link*/
            this.setState({
                currentPageIdentifier: 0
            });

            /**Setting history to landing page */
            AppUtils.pushNavigationHistory(MOODLE_RESET_PIN_PATH_NAME.OPENING_PAGE);

        } catch (error) {
            console.error('handleLoginClick : error :', error);
        }
    };

    /***********************************************Renders************************************************ */

    renderLoader = () => {
        const {
            supportingLanguagesFetchStatus,
            translaionsFetchStatus,
            validateUserDetailsStatus,
            resetPINStatus
        } = this.props;

        return (
            (translaionsFetchStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                supportingLanguagesFetchStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                validateUserDetailsStatus === HTTP_REQUEST_STATUS.IN_PROGRESS ||
                resetPINStatus === HTTP_REQUEST_STATUS.IN_PROGRESS) &&
            AppUtils.getLoader()
        )
    };

    renderSecurityQuestionPage = () => {

        const { currenResetPinPage, userResetPinDetails, moodleTranslationsByLanguage } = this.props;
        const { answer } = this.state;

        return (
            currenResetPinPage === MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_QUESTION_PAGE &&
            <>
                <h1 className='heading_note mb-4'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_security_question']}</h1>

                <h6 className='description'>
                    {moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_answer_security_question']}
                </h6>

                <h6>
                    <img src={userResetPinDetails && userResetPinDetails['questionIconUrl'] && userResetPinDetails['questionIconUrl']} alt='' style={{ width: 20, marginRight: 5, justifyContent: 'center' }} />
                    {userResetPinDetails && userResetPinDetails['questionText'] && userResetPinDetails['questionText']}
                </h6>

                <form autoComplete='off'>
                    <fieldset>
                        {
                            AppUtils.getFormComponent({
                                'key': 'securityAnswer',
                                'type': "custom_style_textfield",
                                'changeCallback': this.handleChange,
                                'value': answer,
                                'placeholder': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_your_answer'],
                                'InputLabelProps': {
                                    style: {
                                        fontSize: 14,
                                        color: SUB_NOTE_GREAY
                                    }
                                },
                                'variant': 'outlined',
                                'size': 'small',
                                'style': { marginTop: '2px' }
                            })
                        }

                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {
                                AppUtils.getFormComponent({
                                    'type': 'button',
                                    'variant': 'contained',
                                    'fullWidth': true,
                                    'handleButtonClick': this.verifyAnswer,
                                    'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_button_next'],
                                    'style': commonStyles.button_active
                                })
                            }
                        </div>
                    </fieldset>
                </form>
            </>
        )
    };

    renderAccountDetailsForm = () => {
        const { currenResetPinPage, moodleTranslationsByLanguage } = this.props;

        const accountDetailTextBoxes = [{ 'type': 'company' }, { 'type': 'employee' }];

        return (
            currenResetPinPage === MOODLE_RESET_PIN_FLOW.ACCOUNT_DETAILS_PAGE &&
            <>
                {this.renderLoader()}
                <h1 className='heading_note mb-4'>{moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_account_details']}</h1>
                <form autoComplete='off'>
                    <fieldset>
                        {accountDetailTextBoxes.map(item => {
                            return (
                                AppUtils.getFormComponent({
                                    'key': item['type'],
                                    'type': "custom_style_textfield",
                                    'changeCallback': this.handleChange,
                                    'value': this.getItemValue(item['type']),
                                    'placeholder': this.getItemLabel(item['type']),
                                    'InputLabelProps': {
                                        style: {
                                            fontSize: 14,
                                            color: SUB_NOTE_GREAY
                                        }
                                    },
                                    'variant': 'outlined',
                                    'size': 'small',
                                    'style': { marginBottom: '0px', marginTop: '0px' }
                                })
                            )

                        })}

                        <div className="mt-4 d-flex align-items-center justify-content-between">
                            {
                                AppUtils.getFormComponent({
                                    'type': 'button',
                                    'variant': 'contained',
                                    'fullWidth': true,
                                    'handleButtonClick': this.validateUserDetails,
                                    'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_button_next'],
                                    'style': commonStyles.button_active
                                })
                            }
                        </div>
                    </fieldset>
                </form>
            </>
        )
    };

    renderFooterNote = () => {

        const { currenResetPinPage, moodleTranslationsByLanguage } = this.props;

        return (
            <FooterNote
                currentPage={currenResetPinPage}
                moodleTranslationsByLanguage={moodleTranslationsByLanguage}
                handleClick={this.handleLoginClick}
            />
        )
    };

    renderSecurityPINPage = () => {

        const { currenResetPinPage, moodleTranslationsByLanguage } = this.props;
        const { newPin } = this.state;

        return (
            currenResetPinPage === MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_PIN_PAGE &&
            <SecurityPINPage
                pin={newPin}
                note={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_new_pin']}
                description={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_new_pin_desc']}
                handlePINChange={this.handlePINChange}
                handlePINConfirmation={this.handlePINConfirmation}
                moodleTranslationsByLanguage={moodleTranslationsByLanguage}
            />
        )
    };

    renderHeaderLogoComponent = () => {

        const { supportingLanguages, currentLanguage } = this.props;

        return (
            <HeaderLogoComponent
                supportingLanguages={supportingLanguages}
                selectedLanguage={currentLanguage}
                handleLanguageChange={this.handleLanguageChange}
            />
        )
    };

    renderCenterImageDisplay = () => {
        const { currenResetPinPage } = this.props;
        return (
            <CenterImageDisplay
                currentPage={currenResetPinPage} />
        )
    };

    renderConfirmationDialog = () => {

        const { showPINResetSuccessDalog, moodleTranslationsByLanguage } = this.props;

        return (
            <ConfirmationDialog
                showDialog={showPINResetSuccessDalog}
                message={moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_pin_reset_success']}
                logo='reset_pin_success_logo'
            />
        )
    };

    renderHeader = () => {
        const { currenResetPinPage, moodleTranslationsByLanguage } = this.props;
        return (
            <HeaderNote currentPage={currenResetPinPage} moodleTranslationsByLanguage={moodleTranslationsByLanguage} />
        )
    };

    renderNotificaionContainer = () => <NotificationContainer />

    render() {
        return (<div className='container-fluid'>
            <div className='row h-100'>
                <div className='col-3 left_panel'>
                    <div class="h-100 d-flex flex-column">
                        {this.renderHeader()}
                        <div className='row m-2 button_container'>
                            <div className='col'>
                                {this.renderAccountDetailsForm()}
                                {this.renderSecurityQuestionPage()}
                                {this.renderSecurityPINPage()}
                            </div>
                        </div>
                        {this.renderFooterNote()}
                    </div>
                </div>
                <div className='col-9 image_container'>
                    {this.renderHeaderLogoComponent()}
                    {this.renderCenterImageDisplay()}
                    {this.renderConfirmationDialog()}
                </div>
                {this.renderNotificaionContainer()}
            </div>
        </div>
        )
    }
}

const commonStyles = {
    button_active: {
        color: '#FFFFFF',
        background: '#2196F3'
    }
};

const mapStateToProps = ({ ReducerMoodleResources, ReducerMoodleResetPin }) => {

    const {
        supportingLanguages,
        supportingLanguagesFetchStatus,
        moodleTranslations,
        translaionsFetchStatus,
        currentLanguage,
        moodleTranslationsByLanguage
    } = ReducerMoodleResources

    const {
        validateUserDetailsStatus,
        userResetPinDetails,
        currenResetPinPage,
        isUserDetailsValid,
        resetPINStatus,
        showPINResetSuccessDalog,
        isPINUpdated
    } = ReducerMoodleResetPin

    return {
        supportingLanguages,
        supportingLanguagesFetchStatus,
        currenResetPinPage,
        validateUserDetailsStatus,
        userResetPinDetails,
        isUserDetailsValid,
        resetPINStatus,
        showPINResetSuccessDalog,
        isPINUpdated,
        moodleTranslations,
        translaionsFetchStatus,
        currentLanguage,
        moodleTranslationsByLanguage
    }
}

export default connect(mapStateToProps, {
    getSupportingLanguages,
    getSupportingLanguagesInProgress,
    getSupportingLanguagesNone,
    validateUserDetailsInProgress,
    validateUserDetailsNone,
    validateUserDetails,
    answerVerified,
    resetPINInProgress,
    resetPIN,
    resetPINNone,
    resetPINHideSuccessDialog,
    getTranslations,
    getTranslationsNone,
    getTranslationsInProgress,
    changeCurrentLanguage,
    setCurrentPageInPINFlow
})(ResetPin) 