export const HTTP_REQUEST_STATUS = {
    "NOT_STARTED" : "NOT_STARTED",
    "IN_PROGRESS" : "IN_PROGRESS",
    "SUCCESS" : "SUCCESS",
    "FAIL" : "FAIL",
};

export const HTTP_REQUEST_STATUS_CODES = {
    OK : 200,
    CREATED : 201
};
