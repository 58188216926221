import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

function ConfirmationDialog({ showDialog, message, logo }) {
    return (
        <Dialog
            open={showDialog}
        >
            <DialogContent>
                <div className='row justify-content-center align-items-center'>
                    <div className='col'>
                        <div className={`d-flex justify-content-center mb-2 ${logo} logo_container p-2`}></div>
                        <h6 className='d-flex justify-content-center'>{message}</h6>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
};

export default ConfirmationDialog;