import { DEFAULT_LANGUAGE_ID, rltLocale } from '../../constants/config';
import { HTTP_REQUEST_STATUS } from '../../constants/constants';
import {
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_IN_PROGRESS,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_SUCCESS,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_ERROR,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_NONE,

    MOODLE_REGISTRATION_GET_TRANSLATIONS_ERROR,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_IN_PROGRESS,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_SUCCESS,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_NONE,

    MOODLE_REGISTRATION_CURRENT_LANGUAGE_CHANGE
} from '../../constants/actions/moodleRegistration/actionTypesMoodleRegistration';
import  {moodleDefaultTranslations} from '../../util/moodleDefaultTranslations';

const intialState = {
    supportingLanguagesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    supportingLanguages: [],
    moodleTranslations: {},
    translaionsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    currentLanguage: DEFAULT_LANGUAGE_ID,
    moodleTranslationsByLanguage: moodleDefaultTranslations,
    isDirectionRTL: false
};

const ReducerMoodleResources = (state = intialState, action) => {

    switch (action.type) {

        case MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_IN_PROGRESS: {
            return {
                ...state,
                supportingLanguagesFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_ERROR: {
            return {
                ...state,
                supportingLanguagesFetchStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_NONE: {
            return {
                ...state,
                supportingLanguagesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_SUCCESS: {
            let supportingLanguages = [];

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {
                supportingLanguages = getSupportingLanguagesDropDown(action.payload.data);
            }
            return {
                ...state,
                supportingLanguagesFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                supportingLanguages: supportingLanguages
            }
        }

        case MOODLE_REGISTRATION_GET_TRANSLATIONS_IN_PROGRESS: {
            return {
                ...state,
                translaionsFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MOODLE_REGISTRATION_GET_TRANSLATIONS_ERROR: {
            return {
                ...state,
                translaionsFetchStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case MOODLE_REGISTRATION_GET_TRANSLATIONS_SUCCESS: {

            let transaltions = {};
            let translationByLanguage = {};

            if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {
                transaltions = action.payload.data;
                translationByLanguage = Object.assign(moodleDefaultTranslations,action.payload.data[DEFAULT_LANGUAGE_ID]);
            }
            return {
                ...state,
                translaionsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                moodleTranslations: transaltions,
                moodleTranslationsByLanguage: translationByLanguage
            }
        }

        case MOODLE_REGISTRATION_GET_TRANSLATIONS_NONE: {
            return {
                ...state,
                translaionsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case MOODLE_REGISTRATION_CURRENT_LANGUAGE_CHANGE: {

            let translations = state.moodleTranslations;
            let languageId = action.payload;
            let translationsByLanguage = {};

            if (typeof translations !== 'undefined') {
                
                let newTranslationObject = Object.assign({},moodleDefaultTranslations);

                if (typeof translations[languageId] !== 'undefined') {
                    translationsByLanguage = Object.assign(newTranslationObject,translations[languageId]);
                }
                else {
                    translationsByLanguage = Object.assign(newTranslationObject,translations[DEFAULT_LANGUAGE_ID]);
                }
            }

            return {
                ...state,
                currentLanguage: action.payload,
                moodleTranslationsByLanguage: translationsByLanguage,
                isDirectionRTL: rltLocale === languageId
            }
        }

        default:
            return state;
    }

};

function getSupportingLanguagesDropDown(data) {
    let result = [];

    try {
        if (typeof data !== 'undefined' && data.length > 0) {
            for (let i = 0; i < data.length; i++) {

                let language = {};

                language['key'] = data[i]['key'];
                language['value'] = data[i]['id'];
                language['label'] = data[i]['text'];

                result.push(language);
            }
        }
    } catch (error) {
        console.error('getSupportingLanguagesDropDown : error :', error);
    }

    return result;
}

export default ReducerMoodleResources;