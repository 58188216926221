export const moodleDefaultTranslations = {
    moodle_web_pin_reset_success: 'Your PIN code has been reset!',
    moodle_web_existing_user_button_label: 'I HAVE A WOVO ACCOUNT',
    moodle_web_new_user_button_label: 'I AM A NEW WOVO USER',
    moodle_web_welcome_note: 'Welcome to WOVO!',
    moodle_web_welcome_desc: 'Get connected with your company',
    moodle_web_create_account_note: 'Create account to get started',
    moodle_web_register_label: 'Register',
    moodle_web_register_desc: 'Please enter your Company Code',
    moodle_web_terms_conditions: 'By registering you agree to the following ',
    moodle_web_terms_conditions_link: 'Terms of Services',
    moodle_web_button_next: 'NEXT',
    moodle_web_already_has_account: 'Already have an acount on this device?',
    moodle_web_login_label: 'Log In',
    moodle_web_yes_button_label: 'YES',
    moodle_web_no_button_label: 'NO',
    moodle_web_confirmation_dialog_text: 'Is this your company?',
    moodle_web_your_company_text: 'Your Company',
    moodle_web_employee_desc: 'Please enter your Employee ID',
    moodle_web_confirm_details: 'Confirm your details',
    moodle_web_details_info: 'Detail info',
    moodle_web_your_employee_id: 'Your Employee ID',
    moodle_web_access_secure: 'Let’s secure your access',
    moodle_web_pin_code: 'PIN Code',
    moodle_web_enter_pin_code: 'Enter your 6 digit PIN code',
    moodle_web_security_question: 'Security Question',
    moodle_web_choose_question: 'Choose security question',
    moodle_web_your_answer: 'Your Answer',
    moodle_web_account_created: 'Your account has been created!',
    moodle_web_welcome_back: 'Welcome back to WOVO',
    moodle_web_reset_pin: 'Reset PIN Code',
    moodle_web_account_details: 'Account Details',
    moodle_web_company_code: 'Company Code',
    moodle_web_employee_id: 'Employee ID',
    moodle_web_take_back: 'Take me back to',
    moodle_web_new_pin: 'New PIN Code',
    moodle_web_new_pin_desc: 'Create a new PIN code to secure access',
    moodle_web_confime_button: 'CONFIRM',
    moodle_web_invalid_company: 'Invalid Company',
    moodle_web_invalid_employee: 'Invalid Employee Id',
    moodle_web_question_load_fail: 'Security questions failed to load',
    moodle_web_languages_fail: 'Supporting languages failed to load',
    moodle_web_fail: 'Network Error',
    moodle_web_valid_company_code: 'Please enter a valid company code',
    moodle_web_valid_employee_id: 'Please enter a valid employee Id',
    moodle_web_valid_pin: 'Enter numeric values',
    moodle_web_full_pin: 'Please enter 6 digit pin',
    moodle_web_answer_not_matching: '	Answer is not matching',
    moodle_web_enter_answer: 'Answer is required',
    moodle_web_invalid_user: 'Invalid User',
    moodle_web_answer_security_question: 'Please answer your security question'
};