import {
    MOODLE_REGISTRATION_NEW_USER,

    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_SUCCESS,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_ERROR,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_TOGGLE_DIALOG,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_VERIFIED,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_INPROGRESS,
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_NONE,
    MOODLE_REGISTRATION_EDIT_COMPANY_CODE,
    MOODLE_REGISTRATION_EDIT_EMPLOYEE_ID,

    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_SUCCESS,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_ERROR,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_INPROGRESS,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_NONE,

    MOODLE_REGISTRATION_CONFIRM_DETAILS,

    MOODLE_REGISTRATION_SAVE_PIN,

    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS,
    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_SUCCESS,
    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_ERROR,
    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_NONE,

    MOODLE_REGISTRATION_REGISTER_USER,
    MOODLE_REGISTRATION_REGISTER_USER_IN_PROGRESS,
    MOODLE_REGISTRATION_REGISTER_USER_SUCCESS,
    MOODLE_REGISTRATION_REGISTER_USER_ERROR,
    MOODLE_REGISTRATION_REGISTER_USER_NONE,

    MOODLE_REGISTRATION_HIDE_SUCCESS_DIALOG,

    MOODLE_REGISTRATION_HIDE_WELCOME_DIALOG,

    MOODLE_REGISTRATION_GET_AVTAR,
    MOODLE_REGISTRATION_GET_AVTAR_IN_PROGRESS,
    MOODLE_REGISTRATION_GET_AVTAR_SUCCESS,
    MOODLE_REGISTRATION_GET_AVTAR_ERROR,
    MOODLE_REGISTRATION_GET_AVTAR_NONE,

    MOODLE_REGISTRATION_NAVIGATION
} from '../../constants/actions/moodleRegistration/actionTypesMoodleRegistration';

/**** moodle registration : new user ****/
export function companyCodeInputForm(params) {
    return {
        type: MOODLE_REGISTRATION_NEW_USER,
        payload: params
    };
}

/**** moodle registration : verify company code ****/
export function verifyCompanyCode(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE,
        payload: params
    };
}

/**** moodle registration : verify company in progress****/
export function verifyCompanyCodeInProgress() {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_INPROGRESS
    };
}

/**** moodle registration : verify company code success ****/
export function verifyCompanyCodeSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_SUCCESS,
        payload: params
    };
}

/**** moodle registration : verify company code error****/
export function verifyCompanyCodeError(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_ERROR,
        payload: params
    };
}

/**** moodle registration : verify company code none****/
export function verifyCompanyCodeNone() {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_NONE
    };
}

/**** moodle registration : verify company code toggle dialog****/
export function toggleDialog(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_TOGGLE_DIALOG,
        payload: params
    };
}

/**** moodle registration : verify company code verified****/
export function companyVerified(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_VERIFIED,
        payload: params
    };
}

/**** moodle registration : edit company code****/
export function editCompanyCode() {
    return {
        type: MOODLE_REGISTRATION_EDIT_COMPANY_CODE
    };
}

/**** moodle registration : edit company code****/
export function editEmployeeId() {
    return {
        type: MOODLE_REGISTRATION_EDIT_EMPLOYEE_ID
    };
}

/**** moodle registration : verify employee id ****/
export function verifyEmployee(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID,
        payload: params
    };
}

/**** moodle registration : verify employee id in progress ****/
export function verifyEmployeeInProgress() {
    return {
        type: MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_INPROGRESS
    };
}

/**** moodle registration : verify employee id success ****/
export function verifyEmployeeSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_SUCCESS,
        payload: params
    };
}

/**** moodle registration : verify employee id error****/
export function verifyEmployeeError(params) {
    return {
        type: MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_ERROR,
        payload: params
    };
}

/**** moodle registration : verify employee none****/
export function verifyEmployeeNone() {
    return {
        type: MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_NONE
    };
}

/**** moodle registration : conform details****/
export function confirmDetails() {
    return {
        type: MOODLE_REGISTRATION_CONFIRM_DETAILS
    };
}

/**moodle registration: save pin */
export function savePIN(params) {
    return {
        type: MOODLE_REGISTRATION_SAVE_PIN,
        payload: params
    };
}

/**moodle registration: get security questions */
export function getSecurityQuestions() {
    return {
        type: MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS
    };
}

/**moodle registration: get security questions */
export function getSecurityQuestionsSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_SUCCESS,
        payload: params
    };
}

/**moodle registration: get security questions */
export function getSecurityQuestionsError(params) {
    return {
        type: MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_ERROR,
        payload: params
    };
}

/**moodle registration: get security questions */
export function getSecurityQuestionsNone() {
    return {
        type: MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_NONE
    };
}

/**moodle registration: Register user */
export function registerUser(params) {
    return {
        type: MOODLE_REGISTRATION_REGISTER_USER,
        payload: params
    };
}

/**moodle registration: Register user */
export function registerUserInProgress() {
    return {
        type: MOODLE_REGISTRATION_REGISTER_USER_IN_PROGRESS
    };
}

/**moodle registration: Register user success */
export function registerUserSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_REGISTER_USER_SUCCESS,
        payload: params
    };
}

/**moodle registration: Register user Error */
export function registerUserError(params) {
    return {
        type: MOODLE_REGISTRATION_REGISTER_USER_ERROR,
        payload: params
    };
}

/**moodle registration: Register user none */
export function registerUserNone(params) {
    return {
        type: MOODLE_REGISTRATION_REGISTER_USER_NONE,
        payload: params
    };
}

/**moodle registration: hide success dialog */
export function hideSuccessDialog() {
    return {
        type: MOODLE_REGISTRATION_HIDE_SUCCESS_DIALOG
    };
}

/**moodle registration: hide welcome dialog */
export function hideWelcomeDialog() {
    return {
        type: MOODLE_REGISTRATION_HIDE_WELCOME_DIALOG
    };
}

/**moodle registration: avtar lit */
export function getAvtarList() {
    return {
        type: MOODLE_REGISTRATION_GET_AVTAR
    };
}

/**moodle registration: avtar list in progress */
export function getAvtarListInProgress() {
    return {
        type: MOODLE_REGISTRATION_GET_AVTAR_IN_PROGRESS
    };
}

/**moodle registration: avtar list success */
export function getAvtarListSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_GET_AVTAR_SUCCESS,
        payload: params
    };
}

/**moodle registration: avtar list error */
export function getAvtarListError(params) {
    return {
        type: MOODLE_REGISTRATION_GET_AVTAR_ERROR,
        payload: params
    };
}

/**moodle registration: avtar list none */
export function getAvtarListNone() {
    return {
        type: MOODLE_REGISTRATION_GET_AVTAR_NONE
    };
}


/**moodle registration : navigation */
export function setCurrentPage(params) {
    return {
        type: MOODLE_REGISTRATION_NAVIGATION,
        payload: params
    }
}