/**Moodle login url */
export const moodleLoginUrl = process.env.REACT_APP_MOODLE_URL;

/**Privacy policy url */
export const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL;

/**WOVO API AWS */
export const wovoAPIUrl = process.env.REACT_APP_WOVO_API_URL;

/**Base url */
export const wovoAPIBaseUrl = wovoAPIUrl + "/api";
