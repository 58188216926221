import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { wovoAPIBaseUrl } from "../../util/keys";
import {
    URL_MOODLE_REGISTRATION_VERIFY_COMPANY,
    URL_MOODLE_REGISTRATION_VERIFY_EMPLOYEE,
    URL_MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS,
    URL_MOODLE_REGISTRATION_REGISTER_USER,
    URL_MOODLE_GET_AVTAR_LIST
} from '../../constants/urls';
import {
    MOODLE_REGISTRATION_VERIFY_COMPANY_CODE,
    MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID,
    MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS,
    MOODLE_REGISTRATION_REGISTER_USER,
    MOODLE_REGISTRATION_GET_AVTAR
} from '../../constants/actions/moodleRegistration/actionTypesMoodleRegistration';
import {
    verifyCompanyCodeSuccess,
    verifyCompanyCodeError,
    verifyEmployeeError,
    verifyEmployeeSuccess,
    getSecurityQuestionsSuccess,
    getSecurityQuestionsError,
    registerUserSuccess,
    registerUserError,
    getAvtarListError,
    getAvtarListSuccess
} from '../../actions/moodleRegistration/actionMoodleRegistration';
import { HTTP_STATUS_CODE } from "../../constants/config";

/************ methods : http *******************/

const httpVerifyCompanyCode = async (data) =>
    await axios.get(wovoAPIBaseUrl + URL_MOODLE_REGISTRATION_VERIFY_COMPANY + data['companyCode'])
        .then(authUser => authUser)
        .catch(error => error);

const httpVerifyEmployee = async (data) => {

    let queryParams = `${data['CompCode']}/${data['EmployeeId']}/${data['DeviceID']}`;

    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_REGISTRATION_VERIFY_EMPLOYEE + queryParams)
        .then(authUser => authUser)
        .catch(error => {
            return error;
        });
};

const httpGetSecurityQuestions = async (data) => {

    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS)
        .then(authUser => authUser)
        .catch(error => {
            return error;
        });
};

const httpRegisterUser = async (data) => {
    return await axios.post(wovoAPIBaseUrl + URL_MOODLE_REGISTRATION_REGISTER_USER, data)
        .then(authUser => authUser)
        .catch(error => {
            return error;
        });
};

const httpGetAvtarList = async () => {
    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_GET_AVTAR_LIST)
        .then(authUser => authUser)
        .catch(error => {
            return error;
        });
};

/************ methods : http response handler *******************/

function* handleHttpVerifyCompanyCode({ payload }) {
    try {
        let dataValue = yield call(httpVerifyCompanyCode, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(verifyCompanyCodeSuccess(dataValue));
        } else {
            yield put(verifyCompanyCodeError(dataValue.message));
        }
    } catch (error) {
        yield put(verifyCompanyCodeError(error));
    }
}

function* handleHttpVerifyEmployee({ payload }) {
    try {
        let dataValue = yield call(httpVerifyEmployee, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(verifyEmployeeSuccess(dataValue));
        } else {
            yield put(verifyEmployeeError(dataValue.message));
        }
    } catch (error) {
        yield put(verifyEmployeeError(error));
    }
}

function* handleHttpGetSecurityQuestions() {
    try {
        let dataValue = yield call(httpGetSecurityQuestions);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(getSecurityQuestionsSuccess(dataValue));
        } else {
            yield put(getSecurityQuestionsError(dataValue.message));
        }
    } catch (error) {
        yield put(getSecurityQuestionsError(error));
    }
}

function* handleHttpRegisterUser({ payload }) {
    try {
        let dataValue = yield call(httpRegisterUser, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(registerUserSuccess(dataValue));
        } else {
            yield put(registerUserError(dataValue.message));
        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}

function* handleHttpGetAvtarList() {
    try {
        let dataValue = yield call(httpGetAvtarList);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(getAvtarListSuccess(dataValue));
        } else {
            yield put(getAvtarListError(dataValue.message));
        }
    } catch (error) {
        yield put(getAvtarListError(error));
    }
}

/************ methods : export access *******************/

export function* verifyCompanyCode() {
    yield takeEvery(MOODLE_REGISTRATION_VERIFY_COMPANY_CODE, handleHttpVerifyCompanyCode)
}

export function* verifyEmployee() {
    yield takeEvery(MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID, handleHttpVerifyEmployee)
}

export function* getSecurityQuestion() {
    yield takeEvery(MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS, handleHttpGetSecurityQuestions)
}

export function* registerUser() {
    yield takeEvery(MOODLE_REGISTRATION_REGISTER_USER, handleHttpRegisterUser)
}

export function* getAvtarList() {
    yield takeEvery(MOODLE_REGISTRATION_GET_AVTAR, handleHttpGetAvtarList)
}

export default function* rootSaga() {
    yield all([
        fork(verifyCompanyCode),
        fork(verifyEmployee),
        fork(getSecurityQuestion),
        fork(registerUser),
        fork(getAvtarList)
    ]);
}