import React from 'react';
import { MOODLE_REGISTRATION_FLOW, MOODLE_RESET_PIN_FLOW } from '../../constants/config';

function HeaderNote({ currentPage, moodleTranslationsByLanguage }) {

    let note = '';
    let subNote = '';

    try {
        switch (currentPage) {
            case MOODLE_REGISTRATION_FLOW.LANDING_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_welcome_note'];
                subNote = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_welcome_desc'];
                break;

            case MOODLE_REGISTRATION_FLOW.COMPANY_INPUT_PAGE:
            case MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_create_account_note'];
                break;

            case MOODLE_REGISTRATION_FLOW.CONFIRMATION_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_confirm_details'];
                break;

            case MOODLE_REGISTRATION_FLOW.SECURITY_PIN_PAGE:
            case MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_access_secure'];
                break;

            case MOODLE_RESET_PIN_FLOW.ACCOUNT_DETAILS_PAGE:
            case MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_PIN_PAGE:
            case MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_QUESTION_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_reset_pin'];
                break;

            default:
                break;
        }
    } catch (error) {
        console.error('HeaderNote : error :', error)
    }


    return (
        <div className='row m-2'>
            <div className='col-9'>
                <h5 className='heading_note'>{note}</h5>
                <h6 className='sub_note'>{subNote}</h6>
            </div>
            <div className='col-3'></div>
        </div>
    )

}

export default HeaderNote