import {all} from 'redux-saga/effects';

import SagaMoodleRegistration from './moodleRegistration/sagaMoodleRegistration';
import sagaMoodleResetPin from './moodleRegistration/sagaMoodleResetPin';
import sagaMoodleResources from './moodleRegistration/sagaMoodleResources';

export default function* rootSaga(getState) {
     yield all([
          SagaMoodleRegistration(),
          sagaMoodleResetPin(),
          sagaMoodleResources()
     ]);
 }