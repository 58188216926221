import React from 'react';
import AppUtils from '../../util/util';

class HeaderLogoComponent extends React.Component {    

    renderLanguageDropdown = () => {

        const { supportingLanguages, selectedLanguage, handleLanguageChange } = this.props;

        return (
            AppUtils.getFormComponent(
                {
                    'key': 'supporting_languages',
                    'type': 'dropdown_menu',
                    'changeCallback': handleLanguageChange,
                    'items': supportingLanguages,
                    'value': selectedLanguage,
                    'defaultValue': selectedLanguage,
                    'variant': "outlined",
                    'size': 'small',
                    'style': { height: '2.1876em' }
                })
        )
    };

    render() {
        return (
            <div className='row m-2 justify-content-start align-items-center'>
                <div className='col-3'>
                    <div className='wovo_logo_container wovo_logo'>
                    </div>
                </div>
                <div className='col-6'></div>
                <div className='col-3'>
                    {this.renderLanguageDropdown()}
                </div>
            </div>
        )
    }
}

export default HeaderLogoComponent; 