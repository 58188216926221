import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { wovoAPIBaseUrl } from "../../util/keys";
import {
    URL_MOODLE_RESET_PIN_VALIDATE_USER_DETAILS,
    URL_MOODLE_RESET_PIN
} from '../../constants/urls';
import {
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS,

    MOODLE_RESET_PIN
} from '../../constants/actions/moodleRegistration/actionTypesMoodleResetPin';
import {
    validateUserDetailsSuccess,
    validateUserDetailsError,
    resetPINSuccess,
    resetPINError
} from '../../actions/moodleRegistration/actionMoodleResetPin';
import { HTTP_STATUS_CODE } from "../../constants/config";

/************ methods : http *******************/

const httpValidateUserDetails = async (data) => {

    let queryParams = `${data['EmployeeId']}/${data['CompCode']}/${data['cultureCodeId']}`;

    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_RESET_PIN_VALIDATE_USER_DETAILS + queryParams)
        .then(authUser => authUser)
        .catch(error => {
            return error;
        });
};

const httpResetPIN = async (data) => {

    let queryParams = `${data['employeeId']}/${data['companyCode']}/${data['PIN']}`;

    return await axios.get(wovoAPIBaseUrl + URL_MOODLE_RESET_PIN + queryParams)
        .then(authUser => authUser)
        .catch(error => {
            return error;
        });
};

/************ methods : http response handler *******************/

function* handleHttpValidateUserDetails({ payload }) {
    try {
        let dataValue = yield call(httpValidateUserDetails, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(validateUserDetailsSuccess(dataValue));
        } else {
            yield put(validateUserDetailsError(dataValue.message));
        }
    } catch (error) {
        yield put(validateUserDetailsError(error));
    }
}

function* handleHttpResetPIN({ payload }) {
    try {
        let dataValue = yield call(httpResetPIN, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(resetPINSuccess(dataValue));
        } else {
            yield put(resetPINError(dataValue.message));
        }
    } catch (error) {
        yield put(resetPINError(error));
    }
}

/************ methods : export access *******************/

export function* validateUserDetails() {
    yield takeEvery(MOODLE_RESET_PIN_VALIDATE_USER_DETAILS, handleHttpValidateUserDetails)
}

export function* resetPIN() {
    yield takeEvery(MOODLE_RESET_PIN, handleHttpResetPIN)
}

export default function* rootSaga() {
    yield all([
        fork(validateUserDetails),
        fork(resetPIN)
    ]);
}