import {
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_ERROR,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_IN_PROGRESS,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_NONE,
    MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_SUCCESS,

    MOODLE_RESET_PIN_ANSWER_VERIFIED,

    MOODLE_RESET_PIN,
    MOODLE_RESET_PIN_IN_PROGRESS,
    MOODLE_RESET_PIN_SUCCESS,
    MOODLE_RESET_PIN_ERROR,
    MOODLE_RESET_PIN_NONE,

    MOODLE_RESET_PIN_HIDE_SUCCESS_DIALOG,

    MOODLE_RESET_PIN_NAVIGATION
} from '../../constants/actions/moodleRegistration/actionTypesMoodleResetPin';

/**Moodle reset pin : validate user details */
export function validateUserDetails(params) {
    return {
        type: MOODLE_RESET_PIN_VALIDATE_USER_DETAILS,
        payload: params
    }
}

/**Moodle reset pin : validate user details : in progress */
export function validateUserDetailsInProgress() {
    return {
        type: MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_IN_PROGRESS
    }
}

/**Moodle reset pin : validate user details : success */
export function validateUserDetailsSuccess(data) {
    return {
        type: MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_SUCCESS,
        payload: data
    }
}

/**Moodle reset pin : validate user details : error */
export function validateUserDetailsError(error) {
    return {
        type: MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_ERROR,
        payload: error
    }
}

/**Moodle reset pin : validate user details : none */
export function validateUserDetailsNone() {
    return {
        type: MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_NONE
    }
}

/**Moodle reset pin : validate user answer */
export function answerVerified() {
    return {
        type: MOODLE_RESET_PIN_ANSWER_VERIFIED
    }
}

/**Moodle reset pin  */
export function resetPIN(params) {
    return {
        type: MOODLE_RESET_PIN,
        payload: params
    }
}

/**Moodle : reset pin  : in progress */
export function resetPINInProgress() {
    return {
        type: MOODLE_RESET_PIN_IN_PROGRESS
    }
}

/**Moodle : reset pin : success */
export function resetPINSuccess(data) {
    return {
        type: MOODLE_RESET_PIN_SUCCESS,
        payload: data
    }
}

/**Moodle : reset pin : error */
export function resetPINError(error) {
    return {
        type: MOODLE_RESET_PIN_ERROR,
        payload: error
    }
}

/**Moodle : reset pin  : none */
export function resetPINNone() {
    return {
        type: MOODLE_RESET_PIN_NONE
    }
}

/**Moodle : reset pin  : none */
export function resetPINHideSuccessDialog() {
    return {
        type: MOODLE_RESET_PIN_HIDE_SUCCESS_DIALOG
    }
}

/**moodle reset pin : navigation */
export function setCurrentPageInPINFlow(params) {
    return {
        type: MOODLE_RESET_PIN_NAVIGATION,
        payload: params
    }
}
