/**reset pin : validate  user details */
export const MOODLE_RESET_PIN_VALIDATE_USER_DETAILS = 'MOODLE_REGISTRATION_RESET_PIN_VALIDATE_USER_DETAILS';
export const MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_IN_PROGRESS = 'MOODLE_REGISTRATION_RESET_PIN_VALIDATE_USER_DETAILS_IN_PROGRESS';
export const MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_SUCCESS = 'MOODLE_REGISTRATION_RESET_PIN_VALIDATE_USER_DETAILS_SUCCESS';
export const MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_ERROR = 'MOODLE_REGISTRATION_RESET_PIN_VALIDATE_USER_DETAILS_ERROR';
export const MOODLE_RESET_PIN_VALIDATE_USER_DETAILS_NONE = 'MOODLE_REGISTRATION_RESET_PIN_VALIDATE_USER_DETAILS_NONE';

export const MOODLE_RESET_PIN_ANSWER_VERIFIED = 'MOODLE_RESET_PIN_ANSWER_VERIFIED';

export const MOODLE_RESET_PIN = 'MOODLE_RESET_PIN';
export const MOODLE_RESET_PIN_IN_PROGRESS = 'MOODLE_RESET_PIN_IN_PROGRESS';
export const MOODLE_RESET_PIN_SUCCESS = 'MOODLE_RESET_PIN_SUCCESS';
export const MOODLE_RESET_PIN_ERROR = 'MOODLE_RESET_PIN_ERROR';
export const MOODLE_RESET_PIN_NONE = 'MOODLE_RESET_PIN_NONE';

export const MOODLE_RESET_PIN_HIDE_SUCCESS_DIALOG = 'MOODLE_RESET_PIN_HIDE_SUCCESS_DIALOG';

export const MOODLE_RESET_PIN_NAVIGATION = 'MOODLE_RESET_PIN_NAVIGATION';

