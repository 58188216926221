/** */
export const MOODLE_REGISTRATION_NEW_USER = 'MOODLE_REGISTRATION_NEW_USER';

/**Company verification */
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE';
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_INPROGRESS = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_INPROGRESS';
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_SUCCESS = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_SUCCESS';
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_ERROR = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_ERROR';
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_NONE = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_NONE';

export const MOODLE_REGISTRATION_EDIT_COMPANY_CODE = 'MOODLE_REGISTRATION_EDIT_COMPANY_CODE';
export const MOODLE_REGISTRATION_EDIT_EMPLOYEE_ID = 'MOODLE_REGISTRATION_EDIT_EMPLOYEE_ID';

/** */
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_TOGGLE_DIALOG = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_TOGGLE_DIALOG';
export const MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_VERIFIED = 'MOODLE_REGISTRATION_VERIFY_COMPANY_CODE_VERIFIED';

/**Employee Verification */
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID';
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_INPROGRESS = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_INPROGRESS';
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_SUCCESS = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_SUCCESS';
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_ERROR = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_ERROR';
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_NONE = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_NONE';
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_TOGGLE_DIALOG = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_TOGGLE_DIALOG';
export const MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_VERIFIED = 'MOODLE_REGISTRATION_VERIFY_EMPLOYEE_ID_VERIFIED';

/** */
export const MOODLE_REGISTRATION_CONFIRM_DETAILS = 'MOODLE_REGISTRATION_CONFIRM_DETAILS';

/** */
export const MOODLE_REGISTRATION_SAVE_PIN = 'MOODLE_REGISTRATION_SAVE_PIN';

/**Get security questions */
export const MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS = 'MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS';
export const MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_SUCCESS = 'MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_SUCCESS';
export const MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_ERROR = 'MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_ERROR';
export const MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_NONE = 'MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS_NONE';

/**Register User */
export const MOODLE_REGISTRATION_REGISTER_USER = 'MOODLE_REGISTRATION_REGISTER_USER';
export const MOODLE_REGISTRATION_REGISTER_USER_IN_PROGRESS = 'MOODLE_REGISTRATION_REGISTER_USER_IN_PROGRESS';
export const MOODLE_REGISTRATION_REGISTER_USER_SUCCESS = 'MOODLE_REGISTRATION_REGISTER_USER_SUCCESS';
export const MOODLE_REGISTRATION_REGISTER_USER_ERROR = 'MOODLE_REGISTRATION_REGISTER_USER_ERROR';
export const MOODLE_REGISTRATION_REGISTER_USER_NONE = 'MOODLE_REGISTRATION_REGISTER_USER_NONE';

/** */
export const MOODLE_REGISTRATION_HIDE_SUCCESS_DIALOG = 'MOODLE_REGISTRATION_HIDE_SUCCESS_DIALOG';

/** */
export const MOODLE_REGISTRATION_HIDE_WELCOME_DIALOG = 'MOODLE_REGISTRATION_HIDE_WELCOME_DIALOG';

/**Supporting languages */
export const MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES = 'MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES';
export const MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_IN_PROGRESS = 'MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_IN_PROGRESS';
export const MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_SUCCESS = 'MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_SUCCESS';
export const MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_ERROR = 'MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_ERROR';
export const MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_NONE = 'MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_NONE';

/** */
export const MOODLE_REGISTRATION_CURRENT_LANGUAGE_CHANGE = 'MOODLE_REGISTRATION_CURRENT_LANGUAGE_CHANGE';

/**Get moodle resources */
export const MOODLE_REGISTRATION_GET_TRANSLATIONS = 'MOODLE_REGISTRATION_GET_TRANSLATIONS';
export const MOODLE_REGISTRATION_GET_TRANSLATIONS_IN_PROGRESS = 'MOODLE_REGISTRATION_GET_TRANSLATIONS_IN_PROGRESS';
export const MOODLE_REGISTRATION_GET_TRANSLATIONS_SUCCESS = 'MOODLE_REGISTRATION_GET_TRANSLATIONS_SUCCESS';
export const MOODLE_REGISTRATION_GET_TRANSLATIONS_ERROR = 'MOODLE_REGISTRATION_GET_TRANSLATIONS_ERROR';
export const MOODLE_REGISTRATION_GET_TRANSLATIONS_NONE = 'MOODLE_REGISTRATION_GET_TRANSLATIONS_NONE';

/**Get Avtars*/
export const MOODLE_REGISTRATION_GET_AVTAR = 'MOODLE_REGISTRATION_GET_AVTAR';
export const MOODLE_REGISTRATION_GET_AVTAR_IN_PROGRESS = 'MOODLE_REGISTRATION_GET_AVTAR_IN_PROGRESS';
export const MOODLE_REGISTRATION_GET_AVTAR_SUCCESS = 'MOODLE_REGISTRATION_GET_AVTAR_SUCCESS';
export const MOODLE_REGISTRATION_GET_AVTAR_ERROR = 'MOODLE_REGISTRATION_GET_AVTAR_ERROR';
export const MOODLE_REGISTRATION_GET_AVTAR_NONE = 'MOODLE_REGISTRATION_GET_AVTAR_NONE';

/**Back  button navigation */
export const MOODLE_REGISTRATION_NAVIGATION = 'MOODLE_REGISTRATION_NAVIGATION';



