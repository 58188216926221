import TextField from "@material-ui/core/TextField";
import React from "react";
import { moodleLoginUrl } from "./keys";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import { PIN_SIZE } from '../constants/config';
import { Button } from "@material-ui/core";

var AppUtils = {

    getFormComponent: function (item) {

        switch (item.type) {

            case "custom_style_textfield":
                return (
                    <div className="form-group">
                        <TextField
                            size={item['size']}
                            error={item['isError']}
                            margin="normal"
                            fullWidth
                            id={item['key']}
                            value={item['value']}
                            onChange={(event) => {
                                if (item.changeCallback) {
                                    item.changeCallback(item['key'], event.target.value)
                                }
                            }}
                            onBlur={() => {
                                if (item.blurCallback) {
                                    item.blurCallback(item['key'])
                                }
                            }}
                            disabled={item['disabled']}
                            label={item['label']}
                            variant={item['variant']}
                            InputLabelProps={item['InputLabelProps']}
                            style={item['style']}
                            placeholder={item['placeholder']}
                        />
                    </div>
                );

            case "dropdown_menu": {
                return (
                    <div className="form-group" style={item['style']}>
                        <Select
                            style={item['style']}
                            error={item['isError']}
                            margin="normal"
                            fullWidth
                            id={item['key']}
                            value={item['value']}
                            onChange={(event) => {
                                if (item.changeCallback) {
                                    item.changeCallback(event)
                                }
                            }}
                            onBlur={() => {
                                if (item.blurCallback) {
                                    item.blurCallback(item['key'])
                                }
                            }}
                            disabled={item['disabled']}
                            label={item['label']}
                            helperText={item['helperText']}
                            variant={item['variant']}
                            size={item['size']}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right"
                                },
                                getContentAnchorEl: null,
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                }
                            }}
                        >
                            {item['items'].map((option, index) => {
                                return (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                );
            }

            case "button": {
                return (
                    <Button
                        fullWidth={item['fullWidth']}
                        style={item['style']}
                        onClick={() => {
                            if (item["handleButtonClick"]) {
                                item["handleButtonClick"]()
                            }
                        }}
                        variant={item['variant']}
                        disabled={item['disabled']}
                        size={item['size']}
                    >
                        {item['label']}
                    </Button>
                )
            }

            default:
                return null

        }
    },

    getLoader: function () {
        return (
            <div className="row justify-content-center">
                <CircularProgress color="secondary" className="mr-2" size={20} />
            </div>
        )
    },

    loadMoodleLoginPage: function () {
        try {
            window.location = moodleLoginUrl;
        } catch (ex) {
            console.error("loadMoodleLoginPage : error : ", ex)
        }
    },

    securityPINSetFocusNextElement: function (index) {
        try {

            let nextElementIndex = index + 1;

            if (nextElementIndex < PIN_SIZE) {
                let nextElementID = `pin_id_${index + 1}`;
                document.getElementById(nextElementID).focus();
            }
        } catch (error) {
            console.error("securityPINSetFocusNextElement : error : ", error)
        }
    },

    securityPINSetFocusPrevElement: function (index) {
        try {
            let prevElementIndex = index - 1;

            if (prevElementIndex >= 0) {
                let prevElementID = `pin_id_${index - 1}`;
                document.getElementById(prevElementID).focus();
            }
        } catch (error) {
            console.error("securityPINSetFocusPrevElement : error : ", error)
        }
    },

    pushNavigationHistory: function (page) {
        try {
            let url = window.location.origin;

            let pathName = window.location.pathname.split('/');

            if (pathName[1] === 'reset_pin') {
                page = page === '' ? page : '/' + page;
                url = `${window.location.origin}/${pathName[1]}${page}`;
            }
            else {
                url = window.location.origin + page;
            }

            window.history.pushState(null, null, url);
        } catch (error) {
            console.error("pushNavigationHistory : error : ", error)
        }
    },

    getBrowserTimeZone: function() {

        let timeZone = '';

        try {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (error) {
            console.error("getBrowserTimeZone : error : ", error);  
        }

        return timeZone;
    }
};

export default AppUtils;
