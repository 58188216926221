import { MOODLE_REGISTRATION_FLOW } from '../../constants/config';
import React from 'react';

function CenterImageDisplay({ currentPage }) {

    let imageName = 'moodle_login_flow_image';

    if (currentPage === MOODLE_REGISTRATION_FLOW.LANDING_PAGE) {
        imageName = 'moodle_register_image';
    }

    return (
        <div className='row' style={{ marginTop: '50px' }}>
            <div className='col-1'></div>
            <div className='col-10'>
                <img src={`vendors/moodle_registration/${imageName}.svg`} alt='' className='center_Image_container' />
            </div>
            <div className='col-1'></div>
        </div>
    )
}

export default CenterImageDisplay;