import { MOODLE_REGISTRATION_FLOW, MOODLE_RESET_PIN_FLOW } from '../../constants/config';
import React from 'react';
import { moodleLoginUrl } from '../../util/keys';

function FooterNote({ currentPage, moodleTranslationsByLanguage, handleClick }) {

    let note = '';
    let loginLabel = '';

    try {
        switch (currentPage) {
            case MOODLE_REGISTRATION_FLOW.COMPANY_INPUT_PAGE:
            case MOODLE_REGISTRATION_FLOW.SECURITY_PIN_PAGE:
            case MOODLE_REGISTRATION_FLOW.EMPLOYEE_INPUT_PAGE:
            case MOODLE_REGISTRATION_FLOW.SECURITY_QUESTION_PAGE:
            case MOODLE_REGISTRATION_FLOW.CONFIRMATION_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_already_has_account'];
                loginLabel = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_login_label'];
                break;

            case MOODLE_RESET_PIN_FLOW.ACCOUNT_DETAILS_PAGE:
            case MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_PIN_PAGE:
            case MOODLE_RESET_PIN_FLOW.RESET_PIN_FLOW_SECURITY_QUESTION_PAGE:
                note = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_take_back'];
                loginLabel = moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_login_label'];
                break;

            default:
                break;
        }

    } catch (error) {
        console.error('FooterNote : error :', error);
    }

    return (
        <div className='row m-2 justify-content-center footer_note'>
            {note}
            <a href={moodleLoginUrl} onClick={handleClick}> {loginLabel}</a>
        </div>
    )
}

export default FooterNote