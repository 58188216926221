import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './assets/vendors/style';
import MoodleRegistration from './containers/moodleRegistration';
import ResetPin from './containers/resetPin';
import Error404 from './components/Error404';
import { createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from './styles/themes/defaultTheme';
import { connect } from 'react-redux';
import { MOODLE_REGISTRATION_PATH_NAME, MOODLE_RESET_PIN_PATH_NAME } from './constants/config';

class App extends Component {

  componentWillMount() {

    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  render() {

    const { isDirectionRTL } = this.props;

    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    return (
      <div className="app-main">
        <Switch>
          <Route path='/' exact component={MoodleRegistration} />
          <Route path={MOODLE_REGISTRATION_PATH_NAME.COMPANY_PAGE} exact component={MoodleRegistration} />
          <Route path={MOODLE_REGISTRATION_PATH_NAME.EMPLOYEE_PAGE} exact component={MoodleRegistration} />
          <Route path={MOODLE_REGISTRATION_PATH_NAME.CONFIRMATION_PAGE} exact component={MoodleRegistration} />
          <Route path={MOODLE_REGISTRATION_PATH_NAME.SECURITY_PIN_PAGE} exact component={MoodleRegistration} />
          <Route path={MOODLE_REGISTRATION_PATH_NAME.SECURITY_QUESTION_PAGE} exact component={MoodleRegistration} />
          <Route path='/reset_pin' exact component={ResetPin} />
          <Route path={`/reset_pin/${MOODLE_RESET_PIN_PATH_NAME.SECURITY_QUESTION_PAGE}`} exact component={ResetPin} />
          <Route path={`/reset_pin/${MOODLE_RESET_PIN_PATH_NAME.SECURITY_PIN_PAGE}`} exact component={ResetPin} />
          <Route component={Error404} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ ReducerMoodleRegistration }) => {
  const {
    isDirectionRTL
  } = ReducerMoodleRegistration;

  return {
    isDirectionRTL
  }
};

export default connect(mapStateToProps, {})(App);
