/**Moodle Registration  */
export const URL_MOODLE_REGISTRATION_VERIFY_COMPANY = '/Company/GetCompanyName/';
export const URL_MOODLE_REGISTRATION_VERIFY_EMPLOYEE = '/Account/ValidateUser/';
export const URL_MOODLE_REGISTRATION_GET_SECURITY_QUESTIONS = '/Account/GetAllSecretQuestions/';
export const URL_MOODLE_REGISTRATION_REGISTER_USER = '/Account/RegisterUserForNewAppAccess/';
export const URL_MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES = '/Resource/GetAppSupportedLanguages/';
/**Moodle reset pin */
export const URL_MOODLE_RESET_PIN_VALIDATE_USER_DETAILS = '/Account/moodleResetPIN/';
export const URL_MOODLE_RESET_PIN = '/Account/newLoginUpdatePIN/';
/**-Get Moodle translations*/
export const URL_MOODLE_GET_TRANSLATIONS = '/Resource/GetAllMoodleResource/';
/**-Get Moodle translations*/
export const URL_MOODLE_GET_AVTAR_LIST = '/Account/GetAllAvtarList/';