import {
    MOODLE_REGISTRATION_GET_TRANSLATIONS,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_IN_PROGRESS,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_SUCCESS,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_ERROR,
    MOODLE_REGISTRATION_GET_TRANSLATIONS_NONE,    

    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_IN_PROGRESS,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_SUCCESS,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_ERROR,
    MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_NONE,

    MOODLE_REGISTRATION_CURRENT_LANGUAGE_CHANGE
} from '../../constants/actions/moodleRegistration/actionTypesMoodleRegistration';

/**** moodle registration : Get translations ****/
export function getTranslations(params) {
    return {
        type: MOODLE_REGISTRATION_GET_TRANSLATIONS,
        payload: params
    };
}

/**** moodle registration : get Translations in progress ****/
export function getTranslationsInProgress() {
    return {
        type: MOODLE_REGISTRATION_GET_TRANSLATIONS_IN_PROGRESS
    };
}

/**** moodle registration : get Translations success ****/
export function getTranslationsSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_GET_TRANSLATIONS_SUCCESS,
        payload: params
    };
}

/**** moodle registration : get Translations error****/
export function getTranslationsError(params) {
    return {
        type: MOODLE_REGISTRATION_GET_TRANSLATIONS_ERROR,
        payload: params
    };
}

/**** moodle registration : get Translations none****/
export function getTranslationsNone() {
    return {
        type: MOODLE_REGISTRATION_GET_TRANSLATIONS_NONE
    };
}

/**Moodle registration : get supporting languages */
export function getSupportingLanguages() {
    return {
        type: MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES
    }
}

/**Moodle registration : get supporting languages in progress */
export function getSupportingLanguagesInProgress() {
    return {
        type: MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_IN_PROGRESS
    }
}

/**Moodle registration : get supporting languages success */
export function getSupportingLanguagesSuccess(params) {
    return {
        type: MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_SUCCESS,
        payload: params
    }
}

/**Moodle registration : get supporting languages error */
export function getSupportingLanguagesError(params) {
    return {
        type: MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_ERROR,
        payload: params
    }
}

/**Moodle registration : get supporting languages none */
export function getSupportingLanguagesNone() {
    return {
        type: MOODLE_REGISTRATION_GET_SUPPORTING_LANGUAGES_NONE
    }
}

/**Moodle registration : Change language */
export function changeCurrentLanguage(data) {
    return {
        type: MOODLE_REGISTRATION_CURRENT_LANGUAGE_CHANGE,
        payload: data
    }
}