import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import ReducerMoodleRegistration from './moodleRegistration/ReducerMoodleRegistration';
import ReducerMoodleResetPin from './moodleRegistration/ReducerMoodleResetPin';
import ReducerMoodleResources from './moodleRegistration/ReducerMoodleResources';

export default (history) => combineReducers({
     router: connectRouter(history),
     ReducerMoodleRegistration: ReducerMoodleRegistration,
     ReducerMoodleResetPin: ReducerMoodleResetPin,
     ReducerMoodleResources: ReducerMoodleResources
 });