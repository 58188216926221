import { TextField, Button } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import React from 'react'
import { SUB_NOTE_GREAY } from '../../constants/customUIConfig';
import AppUtils from '../../util/util';

class SecurityPINPage extends React.Component {

    constructor() {
        super();
        this.state = {
            showPassword: true
        }
    }

    togglePasswordVisibilityIcon = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };

    render() {

        const { pin, note, description, handlePINChange, handlePINConfirmation, moodleTranslationsByLanguage } = this.props;
        const { showPassword } = this.state;

        return (
            <>
                <h1 className='heading_note'>{note}</h1>

                <div className='row align-items-center'>
                    <div className='col-8'>
                        <h6 style={{ color: SUB_NOTE_GREAY }}> {description}</h6>
                    </div>
                    <div className='col-4'>
                        <Button
                            style={{ float: 'right', background: '#FFFFFF' }}
                            onClick={this.togglePasswordVisibilityIcon}
                        >
                            {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlinedIcon />}
                        </Button>
                    </div>
                </div>

                <form autoComplete='off'>
                    <fieldset>
                        <div className='row justify-content-center mb-4'>
                            {
                                pin.map((data, index) => {
                                    return (
                                        <TextField
                                            id={'pin_id_' + index}
                                            style={{
                                                width: 30,
                                                margin: 5,
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                            autoFocus={index === 0}
                                            type={showPassword ? 'text' : 'password'}
                                            key={index + '_id'}
                                            onChange={(event) => handlePINChange(event, index)}
                                            value={data}
                                            variant='standard'
                                            inputProps={{ maxLength: 1 }}
                                            onFocus={(e) => e.target.select()}
                                        ></TextField>
                                    )
                                })
                            }
                        </div>

                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {
                                AppUtils.getFormComponent({
                                    'type': 'button',
                                    'variant': 'contained',
                                    'fullWidth': true,
                                    'handleButtonClick': handlePINConfirmation,
                                    'label': moodleTranslationsByLanguage && moodleTranslationsByLanguage['moodle_web_button_next'],
                                    'style': commonStyles.button_active
                                })
                            }
                        </div>

                    </fieldset>
                </form>
            </>
        )
    }
};

const commonStyles = {
    button_active : {
        color: '#FFFFFF',
        background: '#2196F3'
    }
};

export default SecurityPINPage;